import React from "react";

const FormSelectInput: React.FC<{
  title: string;
  name: string;
  selectedValue: string;
  onChange: () => void;
  obj: object;
}> = ({ title, name, onChange, selectedValue, obj }) => {
  return (
    <div className="grid grid-cols-1 grid-rows-1">
      <div className="grid items-center">
        <label>{title}</label>
      </div>
      <div>
        <select
          className="bg-gray p-4 w-full"
          name={name}
          onChange={onChange}
          value={selectedValue}
        >
          {Object.entries(obj).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FormSelectInput;
