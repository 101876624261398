import React from 'react';

const NumberInput: React.FC<{
  title: string;
  value?: string;
  name: string;
  onChange: () => void;
}> = ({ title, value, onChange, name }) => {
  return (
    <div className="grid grid-cols-1 grid-rows-1">
      <div className="grid items-center">
        <label>
          {title}
          <span className="text-red-400">*</span>
        </label>
      </div>
      <div>
        <input
          autoComplete="nope"
          className="bg-gray p-4 w-full 
          outline-none appearance-none"
          type="number"
          min={0}
          name={name}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default NumberInput;
