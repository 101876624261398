import { any, record, string, z } from "zod";

export enum Region {
  AUSTAITIJOS = "Aukštaitijos",
  DZUKIJOS = "Dzūkijos",
  SUVALKIJOS = "Suvalkijos",
  ZEMAITIJOS = "Žemaitijos",
  MAZOSIOS_LIETUVOS = "Mažosios Lietuvos",
}

export enum DifficultyLevel {
  EASY = "Lengvas",
  NORMAL = "Normalus",
  HARD = "Sunkus",
}

export const updateGameRouteSchema = z.object({
  author: z.string({
    required_error: "Autorius privalomas!",
  }),
  nameEN: z.string({
    required_error: "Pavadinimas angliškai privalomas",
  }),
  nameLT: z.string({
    required_error: "Pavadinimas privalomas",
  }),
  nameRU: z.string({
    required_error: "Pavadinimas rusiškai privalomas",
  }),
  descriptionEN: z.string({
    required_error: "Aprašymas angliškai privalomas",
  }),
  descriptionLT: z.string({
    required_error: "Aprašymas privalomas",
  }),
  length: z.number({ required_error: "Ilgis privalomas" }),
  descriptionRU: z.string({
    required_error: "Aprašymas rusiškai privalomas",
  }),
  difficultyLevel: z.nativeEnum(DifficultyLevel),
  location: z.object({
    geopoint: z.object({ latitude: z.number(), longitude: z.number() }),
  }),
  region: z.nativeEnum(Region),
  encodedPolyline: z.array(z.object({ lat: z.number(), lng: z.number() })),
  photo: record(any()).optional(),
  stepCount: z.number({
    required_error: "Žingsnių skaičius privalomas!",
  }),
  walkingTime: z.number({
    required_error: "Vaikščiojimo laikas minutėmis privalomas!",
  }),
  artefactsIds: z.array(z.string()).optional(),
});

export type updateGameRouteInputs = z.infer<typeof updateGameRouteSchema>;
