import { useLoadScript } from "@react-google-maps/api";
import { IMapView } from "../../../interface/gameRoutes/Route";
import Loader from "../../Loader";
import MapView from "./MapView";

const GoogleMapsView: React.FC<IMapView> = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
  });

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <MapView polylines={props.polylines} artefacts={props.artefacts} />
    </div>
  );
};

export default GoogleMapsView;
