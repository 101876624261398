import React, { useEffect } from 'react';

const Modal: React.FC<{
  modal: boolean;
  setModal: (setModal: boolean) => void;
  text: string;
  time?: number;
  color?: string;
}> = ({ modal, setModal, text, time = 3000, color = 'bg-emerald-500' }) => {
  useEffect(() => {
    setTimeout(() => {
      setModal(false);
    }, time);
  }, [setModal]);

  return (
    <div
      className={`fixed mx-auto top-0 left-0 right-0 text-center z-50 ${color} transition duration-200 ${
        modal ? 'p-5 h-auto' : 'p-0 h-0'
      } `}
    >
      <p className="text-white text-2xl">{text}</p>
    </div>
  );
};

export default Modal;
