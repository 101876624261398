import { DifficultyLevel } from "../schema/addGameRouteSchema";

export const checkDifLevel = (value: string) => {
  if (value === DifficultyLevel.EASY) {
    return DifficultyLevel.EASY;
  } else if (value === DifficultyLevel.NORMAL) {
    return DifficultyLevel.NORMAL;
  } else {
    return DifficultyLevel.HARD;
  }
};
