import { useLoadScript } from "@react-google-maps/api";
import Loader from "../../Loader";
import ArtefactMap from "./ArtefactMap";

const ArtefactGoogleMaps: React.FC<{
  setGeoPoint: (setGeoPoint: { lat: number; lng: number }) => void;
  geoPoint?: { lat: number; lng: number };
}> = ({ setGeoPoint, geoPoint }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
  });

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <ArtefactMap setGeoPoint={setGeoPoint} geoPoint={geoPoint} />
    </div>
  );
};

export default ArtefactGoogleMaps;
