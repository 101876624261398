import { z } from "zod";

export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (ctx.data === "imageFileIsTooBig") {
      return { message: "Failas privalo būti nedaugiau kaip 5mb!" };
    } else if (ctx.data === "badImageFileType") {
      return { message: "Failas privalo būti .png, .jpge, .jpg" };
    } else if (ctx.data === "sourceFileIsTooBig") {
      return { message: "Failas privalo būti nedaugiau kaip 5mb!" };
    } else if (ctx.data === "badSourceFileType") {
      return { message: "Failas privalo būti .mp3, .vaw!" };
    } else if (issue.expected === "object") {
      return { message: "Failas privalomas!" };
    }
  }
  if (issue.code === z.ZodIssueCode.custom) {
    return { message: `less-than-${(issue.params || {}).minimum}` };
  }
  return { message: ctx.defaultError };
};
