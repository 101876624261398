import { Region } from "../schema/addGameRouteSchema";

export const checkRegion = (value: string) => {
  if (value === Region.AUSTAITIJOS) {
    return Region.AUSTAITIJOS;
  } else if (value === Region.DZUKIJOS) {
    return Region.DZUKIJOS;
  } else if (value === Region.MAZOSIOS_LIETUVOS) {
    return Region.MAZOSIOS_LIETUVOS;
  } else if (value === Region.SUVALKIJOS) {
    return Region.SUVALKIJOS;
  } else {
    return Region.ZEMAITIJOS;
  }
};
