import Profile from '../../components/admins/Profile';

const ProfilePage = () => {
  return (
    <div className="flex flex-col items-cener gap-5 p-10 h-full">
      <div className="flex justify-between bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
        <p className="text-white">Administratoriaus profilis</p>
      </div>

      <div className="flex justify-center h-full">
        <Profile />
      </div>
    </div>
  );
};

export default ProfilePage;
