import { GoogleMap, Polyline } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { IArtefact } from "../../../interface/artefacts/artefact";
import ArtefactMarkers from "../markers/ArtefackMarkers";
import PolylineMarkers from "../markers/PolylineMarkers";

const MapUpdate: React.FC<{
  setPolylines: (setPolylines: { lat: number; lng: number }[]) => void;
  polylines: { lat: number; lng: number }[];
  selectedArtefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  artefacts: IArtefact[];
  setArtefacts: (setArtefacts: IArtefact[]) => void;
}> = ({
  setPolylines,
  polylines,
  selectedArtefacts,
  setSelectedArtefacts,
  setArtefacts,
  artefacts,
}) => {
  const [map, setMap] = useState<any>(null);

  const onLoad = useCallback((map) => setMap(map), []);

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      polylines.map((polylines) => {
        bounds.extend({
          lat: polylines.lat,
          lng: polylines.lng,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, selectedArtefacts]);

  const onClickAddMarker = (e: google.maps.MapMouseEvent) => {
    setPolylines([
      ...polylines,
      { lat: e.latLng!.lat(), lng: e.latLng!.lng() },
    ]);
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerClassName="h-[32rem]"
      onClick={onClickAddMarker}
    >
      <ArtefactMarkers
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setArtefacts={setArtefacts}
        artefacts={artefacts}
      />

      <PolylineMarkers
        setPolylines={setPolylines}
        polylines={polylines}
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setArtefacts={setArtefacts}
        artefacts={artefacts}
      />
      <Polyline path={polylines} options={options} />
    </GoogleMap>
  );
};

export default MapUpdate;
