import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <p className="text-3xl text-darkPurple absolute top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        Puslapis nerastas!
      </p>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span className="animate-ping absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-50">
          <FontAwesomeIcon
            className="text-[10rem] text-softPurple absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            icon={faCircleExclamation}
          />
        </span>
      </div>
    </div>
  );
};

export default NotFound;
