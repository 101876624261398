import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const FormInput: React.FC<{
  title: string;
  value?: string;
  name: string;
  maxLength?: number;
  InputType?: string;
  onChange: () => void;
}> = ({ title, value, onChange, name, maxLength, InputType = 'text' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState('');

  useEffect(() => {
    if (InputType === 'password') {
      isVisible ? setType('text') : setType('password');
    }
  }, [isVisible]);

  return (
    <div className="grid grid-cols-1 grid-rows-1">
      <div className="grid items-center">
        <label>
          {title}
          <span className="text-red-400">*</span>
        </label>
      </div>
      <div className="flex">
        <input
          autoComplete="nope"
          className="bg-gray p-4 w-full"
          maxLength={maxLength}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
        />

        {InputType === 'password' && (
          <div className="flex justify-center items-center bg-softPurple w-16" onClick={() => setIsVisible(!isVisible)}>
            <FontAwesomeIcon className="text-white" icon={isVisible ? faEye : faEyeSlash} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormInput;
