const CheckInput: React.FC<{
  onChange?: () => void;
  value: boolean;
  disable?: boolean;
}> = ({ onChange, value, disable = false }) => {
  return (
    <div className="w-full">
      <label className="w-full">Leisti išklausyti audio įrašą jo nepasiekus? ({value ? 'taip' : 'ne'})</label>
      <div className="bg-gray w-full">
        <label htmlFor="toggle-switch" className="flex px-2 py-4">
          <input
            disabled={disable}
            onChange={onChange}
            defaultChecked={value}
            type="checkbox"
            id="toggle-switch"
            className={`${
              disable === false && 'cursor-pointer'
            } h-6 w-12 rounded-full appearance-none bg-white bg-opacity-50 border-2 border-darkPurple checked:bg-softPurple transition duration-200 relative`}
          />
        </label>
      </div>
    </div>
  );
};
export default CheckInput;
