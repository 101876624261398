import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { IMapView } from "../../../interface/gameRoutes/Route";

const MapView: React.FC<IMapView> = (props) => {
  const [map, setMap] = useState<any>(null);

  const onLoad = useCallback((map) => setMap(map), []);

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      props.polylines.map((polylines) => {
        bounds.extend({
          lat: polylines.lat,
          lng: polylines.lng,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, props.polylines]);

  return (
    <GoogleMap onLoad={onLoad} mapContainerClassName="h-[32rem]">
      {props.artefacts?.map((marker: google.maps.LatLngLiteral, index) => {
        return <Marker key={index} position={marker} label={`${index + 1}`} />;
      })}

      {props.polylines?.map((marker: google.maps.LatLngLiteral, index) => {
        return (
          <Marker
            key={index}
            position={marker}
            icon={{
              url: "/images/Ellipse.png",
              anchor: new google.maps.Point(10, 10),
            }}
          />
        );
      })}

      <Polyline path={props.polylines} options={options} />
    </GoogleMap>
  );
};

export default MapView;
