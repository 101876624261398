import { useLoadScript } from "@react-google-maps/api";
import Loader from "../../Loader";
import ArtefactMapView from "../artefactMaps/ArtefactMapView";

const ArtefactGoogleMapView: React.FC<{
  geoPoint?: { lat: number; lng: number };
}> = ({ geoPoint }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
  });

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <ArtefactMapView geoPoint={geoPoint} />
    </div>
  );
};

export default ArtefactGoogleMapView;
