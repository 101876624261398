import '../style/style.css';

const AdminDashbard = () => {
  return (
    <div className="flex h-screen w-full justify-center items-center">
      <div>
        <div className="flex flex-col">
          <img className="h-20" src="/images/bigLogo.png" alt="logo" />
        </div>
        <div className="text-center p-2">
          <p className="text-2xl">Administracinė panelė</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashbard;
