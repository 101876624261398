import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IArtefact } from "../../interface/artefacts/artefact";
import { GameRoute, GameRoutes } from "../../interface/gameRoutes/Route";
import DeleteModal from "../modals/DeleteModal";
import GameRouteModal from "../modals/Modal";
import Pagination from "../RoutePagination";

const GameRoutesLists: React.FC<GameRoutes> = (props) => {
  const [modal, setModal] = useState(false);
  const [artefacts, setArtefacts] = useState<IArtefact[]>([]);
  const [docId, setDocId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);
  const [textInput, setTextInput] = useState("");

  const searchArtefact = props.gameRoutes.filter((route) => {
    if (textInput === "") {
      return route;
    } else {
      return route.nameLT
        .toLocaleLowerCase()
        .includes(textInput.toLocaleLowerCase());
    }
  });

  const handleDelete = async () => {
    if (deleteElement === true) {
      try {
        await axios.delete(`/route/${docId}`, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        let gameRouteArr: GameRoute[] = [];

        props.gameRoutes.map((route) => {
          if (route.id !== docId) {
            gameRouteArr.push(route);
          }
        });

        setDocId("");
        setDeleteElement(false);
        setModal(true);

        props.setGameRoutes(gameRouteArr);
      } catch (error) {}
    }
  };

  useEffect(() => {
    handleDelete();

    const getAllArtefacts = async () => {
      const res = await axios.get(`/artefact`, {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      setArtefacts(res.data);
    };

    getAllArtefacts();
  }, [deleteElement, setDeleteElement]);

  return (
    <div className="w-full">
      <div>
        <div className="flex bg-gray w-full my-4">
          <input
            type="text"
            className="h-full w-full mx-4 py-5 bg-gray outline-0"
            ref={refInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Maršrutų paieška..."
          />

          <div className="flex justify-center items-center">
            <FontAwesomeIcon
              className="text-xl p-5 text-darkPurple"
              icon={faMagnifyingGlass}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full md:min-h-[75vh]">
        {modal && (
          <GameRouteModal
            modal={modal}
            setModal={setModal}
            text="Maršrutas ištrintas!"
          />
        )}

        {deleteModal && (
          <DeleteModal
            item="maršrutą"
            setDeleteElement={setDeleteElement}
            setDeleteModal={setDeleteModal}
          />
        )}

        <Pagination
          items={searchArtefact}
          setDocId={setDocId}
          setDeleteModal={setDeleteModal}
        />
        {/* 
        {searchArtefact.map((route) => (
          <div key={route.id} className="flex justify-between bg-gray rounded">
            <div className="p-4">{route.nameLT}</div>
            <div className="flex bg-softPurple p-4 rounded gap-5">
              <FavIcon
                size="text-md"
                color="text-white"
                to={`/routes/view/${route.id}`}
                icon={faEye}
              />
              <FavIcon
                size="text-md"
                color="text-white"
                to={`/routes/update/${route.id}`}
                icon={faPencil}
              />
              <ActionButton
                icon={faTrashCan}
                onClick={() => {
                  setDocId(route.id);
                  setDeleteModal(true);
                }}
              />
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default GameRoutesLists;
