import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';
import { useEffect } from 'react';

const ArtefactMap: React.FC<{
  setGeoPoint: (setGeoPoint: { lat: number; lng: number }) => void;
  geoPoint?: { lat: number; lng: number };
}> = ({ setGeoPoint, geoPoint }) => {
  const [zoom, setZoom] = useState(7);
  const [position, setPositon] = useState({
    lat: 55.321918,
    lng: 23.958587,
  });

  useEffect(() => {
    if (geoPoint?.lat && geoPoint?.lng) {
      setPositon({ lat: geoPoint?.lat, lng: geoPoint?.lng });
      setZoom(14);
    }
  }, [setPositon]);

  const onClickAddMarker = (e: google.maps.MapMouseEvent) => {
    setGeoPoint({ lat: e.latLng!.lat(), lng: e.latLng!.lng() });
  };

  const onClickRemove = () => {
    setGeoPoint({ lat: 0, lng: 0 });
  };

  return (
    <GoogleMap zoom={zoom} center={position} mapContainerClassName="h-[32rem]" onClick={onClickAddMarker}>
      {geoPoint && <Marker position={{ lat: geoPoint?.lat, lng: geoPoint?.lng }} onDblClick={onClickRemove} />}
    </GoogleMap>
  );
};

export default ArtefactMap;
