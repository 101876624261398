import { ErrorMessage, Field, Form } from 'formik';
import React from 'react';
import FormInput from '../inputs/FormInput';

const ProfileUpdateForm: React.FC<{
  formikValues: {
    email: string;
    newPassword?: string;
    currentPassword: string;
  };
  formikSetFieldValue: (key: string, value: any) => void;
}> = ({ formikValues, formikSetFieldValue }) => {
  return (
    <Form autoComplete="off" className="grid gap-3">
      <div className="py-2">
        <ErrorMessage name="email" className="text-red-400" render={(msg) => <p className="text-red-500">{msg}</p>} />
        <Field
          as={FormInput}
          name="email"
          title="Administratoriaus El. paštas"
          type="text"
          value={formikValues.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => formikSetFieldValue('email', e.target.value)}
        />
      </div>
      <div className="py-2">
        <ErrorMessage name="newPassword" className="text-red-400" render={(msg) => <p className="text-red-500">{msg}</p>} />
        <Field
          as={FormInput}
          name="newPassword"
          title="Administratoriaus naujas slaptažodis"
          InputType="password"
          value={formikValues.newPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => formikSetFieldValue('newPassword', e.target.value)}
        />
      </div>

      <div className="py-2">
        <ErrorMessage name="currentPassword" className="text-red-400" render={(msg) => <p className="text-red-500">{msg}</p>} />
        <Field
          as={FormInput}
          name="currentPassword"
          title="Administratoriaus dabartinis slaptažodis"
          InputType="password"
          value={formikValues.currentPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => formikSetFieldValue('currentPassword', e.target.value)}
        />
      </div>

      <button
        className="bg-gradient-to-r bg-softPurple hover:bg-darkPurple  my-5 text-white p-4  transition duration-700 ease-in-out"
        type="submit"
      >
        Išsaugoti
      </button>
    </Form>
  );
};

export default ProfileUpdateForm;
