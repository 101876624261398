import { faMagnifyingGlass, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { IArtefact, IArtefactArr } from '../../interface/artefacts/artefact';

const Search: React.FC<{
  artefacts: IArtefact[];
  setArtefacts: (setArtefacts: IArtefact[]) => void;
  selectedArtefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  setRemoveArtefact: (setRemoveArtefact: IArtefact[]) => void;
}> = ({ artefacts, setArtefacts, selectedArtefacts, setSelectedArtefacts, setRemoveArtefact }) => {
  const [allArtefacts, setAllArtefacts] = useState<IArtefactArr[]>([]);
  const [textInput, setTextInput] = useState('');
  const refInput = useRef<HTMLInputElement>(null);

  const searchArtefact = artefacts.filter((artefact) => {
    if (textInput === '') {
      return artefact;
    } else {
      return artefact.nameLT.toLocaleLowerCase().includes(textInput.toLocaleLowerCase());
    }
  });

  const onClickForRefInput = () => {
    const input = refInput.current;
    input!.value = '';
  };

  const addArtefact = (selected: IArtefact) => {
    const newSearchArr: IArtefact[] = [];
    artefacts.filter((artefact) => {
      if (artefact.id !== selected.id) {
        newSearchArr.push(artefact);
      }

      setArtefacts(newSearchArr);
    });

    const newArr = [...selectedArtefacts, selected];

    setSelectedArtefacts(newArr);
  };

  const removeArtefact = (selected: IArtefact) => {
    const newSelectedArr: IArtefact[] = [];
    const newRemovedArtefact: IArtefact[] = [];
    selectedArtefacts.filter((artefact) => {
      if (artefact.id !== selected.id) {
        newSelectedArr.push(artefact);
      } else {
        newRemovedArtefact.push(artefact);
        setArtefacts([...artefacts, artefact]);
      }
      setRemoveArtefact(newRemovedArtefact);
      setSelectedArtefacts(newSelectedArr);
    });
  };

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  //Darg and drop artefact in routeCReate and update

  const drop = () => {
    const copyListItems = [...selectedArtefacts];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedArtefacts(copyListItems);
  };

  return (
    <div>
      <label className="text-black">Artefaktų paieška</label>
      <div className="flex bg-gray w-full">
        <input
          type="text"
          className="h-full w-full p-4 bg-gray outline-0"
          ref={refInput}
          onChange={(e) => setTextInput(e.target.value)}
        />

        <div className="flex justify-center items-center p-5">
          <FontAwesomeIcon className="text-xl text-darkPurple" icon={faMagnifyingGlass} />
        </div>
      </div>
      <div
        className={`mt-5 bg-gray h-36 max-h-36
        } ${searchArtefact.length > 3 ? 'overflow-y-scroll' : ''}`}
      >
        {searchArtefact.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <p>Artefaktų nėra!</p>
          </div>
        )}
        {searchArtefact &&
          searchArtefact.map((artefact) => (
            <div
              key={artefact.id}
              onClick={() => {
                addArtefact(artefact);
                setTextInput('');
                onClickForRefInput();
              }}
              className="flex justify-between items-center hover:bg-softPurple hover:text-white p-3"
            >
              <div>{artefact.nameLT}</div>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          ))}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-5 bg-gray">
        {selectedArtefacts &&
          selectedArtefacts.map((artefact, index) => (
            <div
              key={artefact.id}
              onDragStart={() => dragStart(index)}
              onDragEnter={() => dragEnter(index)}
              onDragEnd={drop}
              onClick={() => removeArtefact(artefact)}
              draggable
              className="flex justify-between items-center p-2 bg-darkPurple text-white cursor-pointer hover:bg-red-600 transition active:bg-emerald-600"
            >
              <div className="px-2 truncate">{artefact.nameLT}</div>
              <FontAwesomeIcon icon={faRemove} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Search;
