import { z } from 'zod';

export const updateAdminSchema = z.object({
  email: z
    .string({
      required_error: 'Administratoriaus El. paštas privalomas!',
    })
    .email('Neteisingas El. pašto formatas')
    .optional(),
  password: z
    .string({
      required_error: 'Administratoriaus slaptažodis privalomas!',
    })
    .min(6, 'Slaptažodi ilgis turi būti bent 6 simbolių!')
    .max(25, 'Slaptažodi ilgis turi būti nedaugiau kaip 25 simboliai!')
    .optional(),
});

export type updateAdminInputs = z.infer<typeof updateAdminSchema>;
