import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AdminsPaginaiton from "../AdminsPagination";
import DeleteModal from "../modals/DeleteModal";
import AdminModal from "../modals/Modal";

const AdminList: React.FC<{
  admins: { uid: string; email: string }[];
  setAdmins: (setAdmins: { uid: string; email: string }[]) => void;
}> = ({ admins, setAdmins }) => {
  const [modal, setModal] = useState(false);
  const [uid, setUid] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);
  const [textInput, setTextInput] = useState("");

  const searchAdmins = admins.filter((admin) => {
    if (textInput === "") {
      return admin;
    } else {
      return admin.email
        .toLocaleLowerCase()
        .includes(textInput.toLocaleLowerCase());
    }
  });

  const handleDelete = async () => {
    if (deleteElement === true) {
      try {
        await axios.delete(`/admin/${uid}`, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        let adminsArr: { uid: string; email: string }[] = [];
        admins.map((admin) => {
          if (admin.uid !== uid) {
            adminsArr.push(admin);
          }
        });
        setUid("");
        setDeleteElement(false);
        setModal(true);
        setAdmins(adminsArr);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleDelete();
  }, [deleteElement]);

  return (
    <div className="w-full">
      <div>
        <div className="flex bg-gray w-full my-4">
          <input
            type="text"
            className="text-left block py-5 px-4 h-full w-full bg-gray outline-0"
            ref={refInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Administratorių paieška..."
          />

          <div className="flex justify-center items-center">
            <FontAwesomeIcon
              className="text-xl p-5 text-darkPurple"
              icon={faMagnifyingGlass}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full md:min-h-[75vh]">
        {modal && (
          <AdminModal
            modal={modal}
            setModal={setModal}
            text="Administratorius ištrintas!"
          />
        )}

        {deleteModal && (
          <DeleteModal
            item="administratorių"
            setDeleteElement={setDeleteElement}
            setDeleteModal={setDeleteModal}
          />
        )}

        <AdminsPaginaiton
          items={searchAdmins}
          setUid={setUid}
          setDeleteModal={setDeleteModal}
        />
      </div>
    </div>
  );
};

export default AdminList;
