import React from 'react';
import Loader from '../Loader';

const AdminView: React.FC<{ admin?: { uid: string; email: string }; loading: boolean }> = ({ admin, loading }) => {
  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      <div className="grid gap-5">
        <TableRow title="Administratoriaus El. paštas" value={admin?.email} />
      </div>
    </div>
  );
};

export default AdminView;

const TableRow: React.FC<{ title: string; value?: string }> = ({ title, value }) => {
  return (
    <div>
      <div>
        <h6>{title}</h6>
      </div>
      <div className="bg-gray">
        <p className="p-4">{value}</p>
      </div>
    </div>
  );
};
