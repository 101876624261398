import { faPencil } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewArtefact from "../../components/artefact/ViewArtefact";
import FavIcon from "../../components/buttons/fAwesomeButton";
import { IArtefact } from "../../interface/artefacts/artefact";

const ArtefactViewPage = () => {
  const id = useParams().id;
  const [artefact, setArtefact] = useState<IArtefact>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArtefact = async () => {
      try {
        const res = await axios.get(`/artefact/${id}`, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        setArtefact(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getArtefact();
  }, []);

  return (
    <div className="flex flex-col items-cener gap-5 p-10 h-full">
      <div className="flex justify-between bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
        <p className="text-white">Artefaktas</p>
        <FavIcon
          to={`/artefacts/update/${id}`}
          icon={faPencil}
          color={"text-white"}
          size={"text-md"}
        />
      </div>
      <div className="flex justify-center h-full">
        <ViewArtefact artefact={artefact} loading={loading} />
      </div>
    </div>
  );
};

export default ArtefactViewPage;
