import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ActionButton: React.FC<{
  icon: IconDefinition;
  onClick: () => void;
}> = ({ icon, onClick }) => {
  return (
    <button onClick={onClick}>
      <FontAwesomeIcon className="text-md text-white" icon={icon} />
    </button>
  );
};

export default ActionButton;
