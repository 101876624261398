import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IArtefact } from "../../interface/artefacts/artefact";
import ArtefactPagination from "../ArtefactPagination ";
import DeleteModal from "../modals/DeleteModal";
import GameRouteModal from "../modals/Modal";

interface props {
  artefacts: IArtefact[];
  setArtefacts: (setArtefacts: IArtefact[]) => void;
}

const ArtefactsList: React.FC<props> = (props) => {
  const [modal, setModal] = useState(false);
  const [docId, setDocId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);
  const [textInput, setTextInput] = useState("");

  const searchArtefact = props.artefacts.filter((artefact) => {
    if (textInput === "") {
      return artefact;
    } else {
      return artefact.nameLT
        .toLocaleLowerCase()
        .includes(textInput.toLocaleLowerCase());
    }
  });

  const handleDelete = async () => {
    if (deleteElement === true) {
      try {
        await axios.delete(`/artefact/${docId}`, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        let artefactsArr: IArtefact[] = [];
        props.artefacts.map((artefact) => {
          if (artefact.id !== docId) {
            artefactsArr.push(artefact);
          }
        });
        setDocId("");
        setDeleteElement(false);
        setModal(true);
        props.setArtefacts(artefactsArr);
      } catch (error) {}
    }
  };

  useEffect(() => {
    handleDelete();
  }, [deleteElement]);

  return (
    <div className="w-full">
      <div>
        <div className="flex bg-gray w-full my-4">
          <input
            type="text"
            className="text-left block py-5 px-4 h-full w-full bg-gray outline-0"
            ref={refInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Artefaktų paieška..."
          />

          <div className="flex justify-center items-center">
            <FontAwesomeIcon
              className="text-xl p-5 text-darkPurple"
              icon={faMagnifyingGlass}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full md:min-h-[75vh]">
        {modal && (
          <GameRouteModal
            modal={modal}
            setModal={setModal}
            text="Artefaktas ištrintas!"
          />
        )}

        {deleteModal && (
          <DeleteModal
            item="artefaktą"
            setDeleteElement={setDeleteElement}
            setDeleteModal={setDeleteModal}
          />
        )}

        <ArtefactPagination
          items={searchArtefact}
          setDocId={setDocId}
          setDeleteModal={setDeleteModal}
        />
      </div>
    </div>
  );
};

export default ArtefactsList;
