import axios from "axios";
import FormData from "form-data";
import { Formik, FormikState } from "formik";
import { useEffect, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePrompt } from "../../hooks/usePromptBlocker";
import { IArtefact, IArtefactArr } from "../../interface/artefacts/artefact";
import {
  addGameRouteInputs,
  addGameRouteSchema,
  DifficultyLevel,
  Region,
} from "../../schema/addGameRouteSchema";
import GameRouteForm from "../forms/GameRouteForm";
import GoogleMaps from "../googleMap/routesMaps/GoogleMaps";
import Search from "../inputs/Search";
import Loader from "../Loader";
import GameRouteModal from "../modals/Modal";

const CreateGameRoute = () => {
  const [modal, setModal] = useState(false);
  const [polylines, setPolylines] = useState<{ lat: number; lng: number }[]>(
    []
  );
  const [meters, setMeters] = useState<number>(0);

  const [AllArtefacts, setAllArtefacts] = useState<IArtefactArr[]>([]);
  const [artefacts, setArtefacts] = useState<IArtefactArr[]>([]);
  const [selectedArtefacts, setSelectedArtefacts] = useState<IArtefact[]>([]);
  const [removeArtefact, setRemoveArtefact] = useState<IArtefact[]>([]);
  const [mapError, setMapError] = useState("");
  const [loading, setLoading] = useState(true);
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    setChangeCount(changeCount + 1);
    if (changeCount > 1) {
      return;
    }
  }, [selectedArtefacts, polylines]);

  useEffect(() => {
    const removingMapErorr = () => {
      if (polylines.length > 0) {
        setMapError("");
      }
    };

    removingMapErorr();
  }, [polylines]);

  useEffect(() => {
    const getAllArtefacts = async () => {
      const newArrtefactArr: IArtefactArr[] = [];
      const res = await axios.get("/artefact", { withCredentials: true });

      res.data.forEach((artefact: IArtefactArr) => {
        newArrtefactArr.push(artefact);
      });

      setArtefacts(newArrtefactArr);
      setAllArtefacts(newArrtefactArr);
      setLoading(false);
    };
    getAllArtefacts();
  }, []);

  const handleSubmit = async (
    values: addGameRouteInputs,
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              photo?: string | Record<string, any> | undefined;
              author: string;
              nameEN: string;
              nameLT: string;
              nameRU: string;
              descriptionEN: string;
              descriptionLT: string;
              length: number;
              descriptionRU: string;
              difficultyLevel: DifficultyLevel;
              location: { geopoint: { latitude: number; longitude: number } };
              region: Region;
              encodedPolyline: { lat: number; lng: number }[];
              stepCount: number;
              walkingTime: number;
              artefactsIds?: string[];
            }>
          >
        | undefined
    ) => void
  ) => {
    if (polylines[0] === undefined) {
      return setMapError("Pasirinkite pradžios tašką!");
    }

    const formData = new FormData();
    formData.append("photo", values.photo);

    const selectedArtfeactIds: string[] = [];

    selectedArtefacts.forEach((artefact) => {
      selectedArtfeactIds.push(artefact.id);
    });

    values.length = meters;
    values.location.geopoint.latitude = polylines[0].lat;
    values.location.geopoint.longitude = polylines[0].lng;
    values.encodedPolyline = polylines;
    values.artefactsIds = selectedArtfeactIds;

    setModal(true);
    const res = await axios.post("/route", values, {
      withCredentials: true,

      headers: {
        Accept: "application/json",
      },
    });

    resetForm({});

    if (res.status === 201) {
      await axios.post(`/route/upload/${res.data.id}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
    }
    setSelectedArtefacts([]);
    setPolylines([]);
    setMapError("");
    setArtefacts(AllArtefacts);
  };

  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && (
        <GameRouteModal
          modal={modal}
          setModal={setModal}
          text="Maršrutas sukurtas!"
        />
      )}

      <div>
        {mapError && <div className="text-red-500">{mapError}</div>}
        <div>
          <p className="text-darkPurple text-xl font-bold">
            Pasirinkite artefaktus maršrute
          </p>
          <Search
            artefacts={artefacts}
            setArtefacts={setArtefacts}
            selectedArtefacts={selectedArtefacts}
            setSelectedArtefacts={setSelectedArtefacts}
            setRemoveArtefact={setRemoveArtefact}
          />
        </div>
        <div className="bg-gradient-to-r from-darkPurple to-softPurple p-1 my-5">
          <GoogleMaps
            setPolylines={setPolylines}
            polylines={polylines}
            meters={meters}
            setMeters={setMeters}
            selectedArtefacts={selectedArtefacts}
            setSelectedArtefacts={setSelectedArtefacts}
            setArtefacts={setArtefacts}
            artefacts={artefacts}
          />
        </div>
      </div>

      <Formik
        initialValues={{
          author: "",
          nameEN: "",
          nameLT: "",
          nameRU: "",
          descriptionEN: "",
          descriptionLT: "",
          descriptionRU: "",
          difficultyLevel: DifficultyLevel.EASY,
          length: meters,
          location: {
            geopoint: {
              latitude: 0,
              longitude: 0,
            },
          },
          region: Region.AUSTAITIJOS,
          encodedPolyline: polylines,
          photo: "undified",
          stepCount: 0,
          walkingTime: 0,
          artefactsIds: [],
        }}
        validationSchema={toFormikValidationSchema(addGameRouteSchema)}
        onSubmit={(values: addGameRouteInputs, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {(formik) => {
          return (
            <GameRouteForm
              dirty={formik.dirty || changeCount > 1}
              meters={meters}
              formikSetFieldValue={formik.setFieldValue}
              formikValues={formik.values}
              setMapError={setMapError}
              polylines={polylines}
              formikErrors={formik.errors}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateGameRoute;
