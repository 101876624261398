import axios from 'axios';
import { Formik, FormikState } from 'formik';
import React, { useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import GameRouteModal from '../modals/Modal';
import { addAdminSchema, addAdminInputs } from '../../schema/addAdminSchema';
import AdminForm from '../forms/AdminForm';

const AdminCreate: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (
    values: { email: string; password: string },
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              email: string;
              password: string;
            }>
          >
        | undefined
    ) => void
  ) => {
    try {
      await axios.post('/admin', values, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
        },
      });

      setError('');
      setModal(true);
      resetForm({});
    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="w-full my-4">
      {modal && <GameRouteModal modal={modal} setModal={setModal} text="Administratorius sukurtas!" />}
      {error && <p className="text-red-600">{error}</p>}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={toFormikValidationSchema(addAdminSchema)}
        onSubmit={(values: addAdminInputs, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {(formik) => {
          return <AdminForm formikSetFieldValue={formik.setFieldValue} formikValues={formik.values} />;
        }}
      </Formik>
    </div>
  );
};

export default AdminCreate;
