import { faEye, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ActionButton from "./buttons/ActionButton";
import FavIcon from "./buttons/fAwesomeButton";

const AdminsPaginaiton: React.FC<{
  items: { uid: string; email: string }[];
  setUid: (setUid: string) => void;
  setDeleteModal: (setDeleteModal: boolean) => void;
}> = ({ items, setUid, setDeleteModal }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemPerPage] = useState(18);

  const pagesVisited = pageNumber * itemsPerPage;

  const pageRangeDisplayed = () => {
    window.onresize = function () {
      if (window.innerWidth < 767) {
        setItemPerPage(6);
      } else {
        setItemPerPage(18);
      }
    };
  };

  useEffect(() => {
    pageRangeDisplayed();
  }, [setItemPerPage]);

  const displayItems = items
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div key={item.uid} className="flex justify-between bg-gray rounded">
          <div className="p-4 truncate text-ellipsis">{item.email}</div>
          <div className="flex bg-softPurple p-4 rounded gap-5">
            <FavIcon
              size="text-md"
              color="text-white"
              to={`/admin/view/${item.uid}`}
              icon={faEye}
            />
            <FavIcon
              size="text-md"
              color="text-white"
              to={`/admin/update/${item.uid}`}
              icon={faPencil}
            />
            <ActionButton
              icon={faTrashCan}
              onClick={() => {
                setUid(item.uid);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>
      );
    });

  const pageCounter = Math.ceil(items.length / itemsPerPage);

  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 grid-rows-1 md:grid-rows-2 w-full my-4">
        {displayItems}
      </div>
      {displayItems.length > 0 && (
        <div className="rounded">
          <ReactPaginate
            pageCount={pageCounter}
            previousLabel={"Atgal"}
            nextLabel={"Toliau"}
            onPageChange={changePage}
            containerClassName={
              "flex justify-center items-center text-white gap-2 bg-darkPurple p-2 rounded"
            }
            previousLinkClassName={"p-2 rounded-[12px] mr-5"}
            nextLinkClassName={"p-2 rounded-[12px] ml-5"}
            activeClassName={"bg-softPurple rounded-[12px]"}
            pageLinkClassName={
              "flex justify-center items-center w-10 h-10 border-2  text-white text-center hover:bg-softPurple transition rounded-[12px]"
            }
          />
        </div>
      )}
    </>
  );
};

export default AdminsPaginaiton;
