import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import './style/style.css';

axios.defaults.baseURL = process.env.REACT_APP_API;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLFormElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
