import { Marker } from "@react-google-maps/api";
import React from "react";
import { IArtefact } from "../../../interface/artefacts/artefact";

interface PolylineMarkers {
  polylines: { lat: number; lng: number }[];
  setPolylines: (setPolylines: { lat: number; lng: number }[]) => void;
  selectedArtefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  artefacts: IArtefact[];

  setArtefacts: (setArtefacts: IArtefact[]) => void;
}

const PolylineMarkers: React.FC<PolylineMarkers> = ({
  setPolylines,
  polylines,
  selectedArtefacts,
  setSelectedArtefacts,
  setArtefacts,
  artefacts,
}) => {
  const onClickRemove = (e: google.maps.MapMouseEvent) => {
    setPolylines(
      polylines.filter(
        (des) => des.lat !== e.latLng!.lat() && des.lng !== e.latLng!.lng()
      )
    );

    setSelectedArtefacts(
      selectedArtefacts.filter((artefact) => {
        if (
          artefact.latitude !== e.latLng!.lat() &&
          artefact.longitude !== e.latLng!.lng()
        ) {
          return artefact;
        } else {
          setArtefacts([...artefacts, artefact]);
        }
      })
    );
  };

  return (
    <>
      {polylines.map(
        (marker: google.maps.LatLng | google.maps.LatLngLiteral, index) => {
          return (
            <Marker
              key={index}
              position={marker}
              icon={{
                url: "/images/Ellipse.png",
                anchor: new google.maps.Point(7, 8),
              }}
              onClick={onClickRemove}
            />
          );
        }
      )}
    </>
  );
};

export default PolylineMarkers;
