import { z } from 'zod';

export const updateProfileSchema = z.object({
  email: z
    .string({
      required_error: 'Administratoriaus El. paštas privalomas!',
    })
    .email('El. pašto formatas neteisingas!'),
  newPassword: z
    .string({
      required_error: 'Administratoriaus naujas slaptažodis privalomas!',
    })
    .min(6, 'Slaptažodi ilgis turi būti bent 6 simbolių!')
    .max(25, 'Slaptažodi ilgis turi būti nedaugiau kaip 25 simboliai!')
    .optional(),
  currentPassword: z.string({
    required_error: 'Administratoriaus dabartinis slaptažodis privalomas!',
  }),
});

export type updateProfileSchemaInputs = z.infer<typeof updateProfileSchema>;
