import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationButton from '../buttons/NavigationButton';
import {
  faAngleRight,
  faArrowRightFromBracket,
  faIdCard,
  faMapLocationDot,
  faObjectGroup,
  faUser,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import FavIcon from '../buttons/fAwesomeButton';
import axios from 'axios';

const Nav: React.FC<{
  isLogged: boolean;
  setIsLogged: (setIsLogged: boolean) => void;
  isSuperAdmin: boolean;
}> = ({ isLogged, setIsLogged, isSuperAdmin }) => {
  const location = useLocation().pathname;
  const activeRoutes = location === '/routes' ? 'bg-darkPurple w-full' : '';
  const activeArtefacts = location === '/artefacts' ? 'bg-darkPurple w-full' : '';
  const activeAdmins = location === '/admins' ? 'bg-darkPurple w-full' : '';
  const activeProfile = location === '/profile' ? 'bg-darkPurple w-full' : '';
  const activeLogin = location === '/login' ? 'bg-darkPurple w-full' : '';
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await axios.get('/logout', { withCredentials: true });
      setIsLogged(false);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <nav className="static md:fixed transition-all ease-in-out shadow-2xl md:min-h-screen h-full bg-purple">
      <div className="md:w-56 h-full">
        <div className="flex items-center justify-between h-[8%]">
          <div className="flex p-5">
            <Link to="/home">
              <img className="w-30" src="/images/logo.png" alt="logo" />
            </Link>
          </div>
        </div>
        {isLogged ? (
          <div className="flex flex-col justify-between h-[92%]">
            <div>
              <div>
                <p className="text-white opacity-80 p-4 text-xs">Meniu</p>
              </div>
              <div className="justify-start md:justify-center items-center w-full">
                <div className={`flex gap-5 p-4 group ${activeRoutes}`}>
                  <FavIcon size="text-md" color="text-white" to="/routes" icon={faMapLocationDot} />
                  <div>
                    <NavigationButton title="Maršrutai" to="/routes" />
                  </div>
                  <div className="flex justify-end w-full">
                    <FavIcon size="text-xs" color="text-white" to="/artefacts" icon={faAngleRight} />
                  </div>
                </div>

                <div className={`flex gap-5 p-4 group ${activeArtefacts}`}>
                  <FavIcon size="text-md" color="text-white" to="/artefacts" icon={faObjectGroup} />

                  <div className="group-hover:opacity-100">
                    <NavigationButton title="Artefaktai" to="/artefacts" />
                  </div>

                  <div className="flex justify-end w-full">
                    <FavIcon size="text-xs" color="text-white" to="/artefacts" icon={faAngleRight} />
                  </div>
                </div>
                <div className={`flex gap-5 p-4 group ${activeProfile}`}>
                  <FavIcon size="text-md" color="text-white" to="/profile" icon={faIdCard} />

                  <div className="group-hover:opacity-100">
                    <NavigationButton title="Profilis" to="/profile" />
                  </div>

                  <div className="flex justify-end w-full">
                    <FavIcon size="text-xs" color="text-white" to="/profile" icon={faAngleRight} />
                  </div>
                </div>
                {isSuperAdmin && (
                  <div className={`flex gap-5 p-4 group ${activeAdmins}`}>
                    <FavIcon size="text-md" color="text-white" to="/admins" icon={faUser} />

                    <div className="group-hover:opacity-100">
                      <NavigationButton title="Administratoriai" to="/admins" />
                    </div>

                    <div className="flex justify-end w-full">
                      <FavIcon size="text-xs" color="text-white" to="/admins" icon={faAngleRight} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="group hover:bg-darkPurple w-full cursor-pointer">
              <div onClick={() => handleLogOut()} className="flex gap-5 p-3 group">
                <div>
                  <p className="text-gray opacity-60">Atsijungti</p>
                </div>
                <div className="flex justify-end w-full">
                  <FavIcon size="text-md" color="text-white" to="/" icon={faArrowRightFromBracket} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="justify-start md:justify-center items-center w-full">
            <div className={`flex gap-5 p-4 group ${activeLogin}`}>
              <FavIcon size="text-md" color="text-white" to="/routes" icon={faUserCheck} />
              <div>
                <NavigationButton title="Prisijungti" to="/" />
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Nav;
