import AdminCreate from '../../components/admins/AdminCreate';

const AdminCreatePage = () => {
  return (
    <div className="flex flex-col items-cener gap-5 p-10 h-full">
      <div className="bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
        <p className="text-white">Naujas administratorius</p>
      </div>
      <div className="flex justify-center h-full">
        <AdminCreate />
      </div>
    </div>
  );
};

export default AdminCreatePage;
