import axios from "axios";
import { Formik, FormikState } from "formik";
import React, { useEffect, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";
import GameRouteModal from "../modals/Modal";
import Loader from "../Loader";
import ProfileUpdateForm from "../forms/ProfileUpdateForm";
import {
  updateProfileSchema,
  updateProfileSchemaInputs,
} from "../../schema/updateProfileSchema";

const Profile: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState<{ email: string }>();

  const [loading, setLoading] = useState(false);

  const getProfile = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/profile", { withCredentials: true });
      setProfile(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleSubmit = async (
    values: { email: string; newPassword?: string; currentPassword: string },
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              email: string;
              newPassword?: string;
              currentPassword: string;
            }>
          >
        | undefined
    ) => void
  ) => {
    setLoading(true);
    try {
      const res = await axios.patch("/profile/", values, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      });
      resetForm({});
      setError("");
      setModal(true);
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && (
        <GameRouteModal
          modal={modal}
          setModal={setModal}
          text="Administratorius atnaujintas!"
        />
      )}
      {error && <p className="text-red-600">{error}</p>}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: profile?.email || "",
          newPassword: "",
          currentPassword: "",
        }}
        validationSchema={toFormikValidationSchema(updateProfileSchema)}
        onSubmit={(values: updateProfileSchemaInputs, { resetForm }) => {
          handleSubmit(values, resetForm);
          profile!.email = values.email;
        }}
      >
        {(formik) => {
          return (
            <ProfileUpdateForm
              formikSetFieldValue={formik.setFieldValue}
              formikValues={formik.values}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default Profile;
