import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArtefactList from "../../components/artefact/ArtefactList";
import { IArtefact } from "../../interface/artefacts/artefact";
import Loading from "../../components/Loader";

const Artefacts = () => {
  const [artefacts, setArtefacts] = useState<IArtefact[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getAllArtefacts = async () => {
      try {
        const res = await axios.get("/artefact", {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        setArtefacts(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getAllArtefacts();
  }, [setArtefacts]);

  if (loading) {
    return (
      <div className="flex justify-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-10 min-h-screen">
      <div className="flex justify-between items-center w-full h-auto">
        <p className="p-4 text-darkPurple text-xl">Artefaktų sąrašas</p>
        <div className="p-1">
          <Link to={"/artefacts/create"}>
            <div className="text-white p-4 bg-softPurple hover:bg-darkPurple transition rounded-[12px]">
              Kurti Artefaktą
            </div>
          </Link>
        </div>
      </div>
      <ArtefactList setArtefacts={setArtefacts} artefacts={artefacts} />
    </div>
  );
};

export default Artefacts;
