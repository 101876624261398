import {
  faCheck,
  faCirclePlay,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form } from "formik";
import React, { useEffect, useState } from "react";

import { usePrompt } from "../../hooks/usePromptBlocker";
import { IArtefactForm } from "../../interface/artefacts/artefact";
import { photoValidation } from "../../utils/imageValidation";
import { sourceValidation } from "../../utils/sourceValidation";
import CheckInput from "../inputs/CheckInput";
import FormTextarea from "../inputs/FormikTextarea";
import FormInput from "../inputs/FormInput";
import Loader from "../Loader";

const ArtefactForm: React.FC<IArtefactForm> = ({
  dirty,
  formikValues,
  formikSetFieldValue,
  setMapError,
  geoPoint,
  formikErrors,
  photoUrl,
  audioUrl,
}) => {
  usePrompt(
    "Ar tikrai norite išeiti? Neišsaugoti duomenys bus prarasti.",
    dirty
  );
  const initInputValues = {
    sourceEN: "",
    sourceLT: "",
    sourceRU: "",
    photo: "",
  };
  const initBooleanValues = {
    sourceEN: false,
    sourceLT: false,
    sourceRU: false,
    photo: false,
  };

  const uploadedBoolean = {
    sourceEN: false,
    sourceLT: false,
    sourceRU: false,
    photo: false,
  };

  const [inputValue, setInputValue] = useState(initInputValues);
  const [loading, setLoading] = useState(initBooleanValues);
  const [uploaded, setUploaded] = useState(uploadedBoolean);

  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [updatedImage, setUpdatedImage] = useState<string | ArrayBuffer | null>(
    null
  );
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audio, setAudio] = useState<string | ArrayBuffer | null>(null);
  const [updatedAudio, setUpdatedAudio] = useState<string | ArrayBuffer | null>(
    null
  );

  const imageFileOnchange = () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target!.result);
    };
    if (photoFile !== null) {
      reader.readAsDataURL(photoFile!);
    }
  };

  imageFileOnchange();

  const audioFileOnchange = () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setAudio(e.target!.result);
    };
    if (audioFile !== null) {
      reader.readAsDataURL(audioFile!);
    }
  };

  audioFileOnchange();

  useEffect(() => {
    if (inputValue.photo !== "") {
      initBooleanValues.photo = false;
      setLoading(initBooleanValues);
      uploaded.photo = true;
    }

    if (inputValue.sourceLT !== "") {
      initBooleanValues.sourceLT = false;
      setLoading(initBooleanValues);
      uploaded.sourceLT = true;
    }
  }, [audio, image]);

  return (
    <Form autoComplete="off" className="grid gap-3">
      <div className="py-2">
        <ErrorMessage
          name="nameEN"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameEN"
          title="Artefakto pavadinimas angliškai"
          type="text"
          value={formikValues.nameEN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameEN", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="nameLT"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameLT"
          title="Artefakto pavadinimas"
          type="text"
          value={formikValues.nameLT}
          maxLength={40}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameLT", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="nameRU"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameRU"
          title="Artefakto pavadinimas rusiškai"
          type="text"
          value={formikValues.nameRU}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameRU", e.target.value)
          }
        />
      </div>

      <div className="py-2">
        <ErrorMessage
          name="audioRecordings.nameEN"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameEN"
          title="Audio pavadinimas angliškai"
          type="text"
          value={formikValues.audioRecordings.nameEN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("audioRecordings.nameEN", e.target.value)
          }
        />
      </div>

      <ErrorMessage
        name="audioRecordings.sourceEN"
        className="text-red-400"
        render={(msg) => <p className="text-red-500">{msg}</p>}
      />
      {inputValue.sourceEN && <p>{inputValue.sourceEN}</p>}
      <label
        htmlFor="inputFileSourceEN"
        className="bg-darkPurple cursor-pointer"
      >
        <div className="flex justify-center items-center p-4 gap-5">
          <p className="text-white">Įkelti angliška audio įrašą</p>
          <FontAwesomeIcon
            className="text-white text-2xl"
            icon={faCirclePlay}
          />
        </div>
        <input
          className="hidden"
          id="inputFileSourceEN"
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue(
              "audioRecordings.sourceEN",
              sourceValidation(e.target.files![0])
            );
            inputValue.sourceEN = e.target.files![0].name;
            setInputValue(inputValue);
          }}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            e.currentTarget.value = "";
          }}
        />
      </label>

      <div className="flex flex-col justify-start items-center py-2s">
        <Field
          as={CheckInput}
          name="playAudio"
          value={formikValues.audioRecordings.playAudio}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue("audioRecordings.playAudio", e.target.checked);
          }}
        />
      </div>

      <div className="py-2">
        <ErrorMessage
          name="audioRecordings.nameLT"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="audioRecordings.nameLT"
          title="Audio pavadinimas"
          type="text"
          value={formikValues.audioRecordings.nameLT}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("audioRecordings.nameLT", e.target.value)
          }
        />
      </div>

      {audioUrl && (
        <div className="py-2">
          <label>Dabartinis audio įrašas</label>
          {updatedAudio !== null ? (
            <div className="bg-gray">
              <audio
                className="w-full"
                src={updatedAudio?.toString()}
                controls
              />
            </div>
          ) : (
            <div className="bg-gray">
              <audio className="w-full" src={audioUrl} controls />
            </div>
          )}
        </div>
      )}

      <ErrorMessage
        name="audioRecordings.sourceLT"
        className="text-red-400"
        render={(msg) => <p className="text-red-500">{msg}</p>}
      />
      {loading.sourceLT ? (
        <Loader />
      ) : (
        inputValue.sourceLT && (
          <div className="flex flex-col gap-5">
            {audio !== null && (
              <div>
                <label>Naujas audio įrašas</label>
                <div className="bg-gray">
                  <audio className="w-full" src={audio?.toString()} controls />
                </div>
              </div>
            )}
            <div className="flex gap-4 p-4 bg-gray">
              <FontAwesomeIcon
                className="text-darkPurple text-2xl"
                icon={faCirclePlay}
              />
              <p>{inputValue.sourceLT}</p>
            </div>
          </div>
        )
      )}
      <label
        htmlFor="inputFileSourceLT"
        className="bg-darkPurple cursor-pointer"
      >
        <div className="flex justify-center items-center p-4 gap-5">
          <p className="text-white">Įkelti audio įrašą</p>

          {uploaded.sourceLT ? (
            <FontAwesomeIcon className="text-white text-2xl" icon={faCheck} />
          ) : (
            <FontAwesomeIcon
              className="text-white text-2xl"
              icon={faCirclePlay}
            />
          )}
        </div>
        <input
          className="hidden"
          id="inputFileSourceLT"
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue(
              "audioRecordings.sourceLT",
              sourceValidation(e.target.files![0])
            );
            inputValue.sourceLT = e.target.files![0].name;
            setInputValue(inputValue);
            setAudioFile(e.target.files![0]);
          }}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            initBooleanValues.sourceLT = false;
            uploaded.sourceLT = false;
            inputValue.sourceLT = "";
            e.currentTarget.value = "";
            setAudio(null);
            setAudioFile(null);
            setInputValue(inputValue);
            formikSetFieldValue("audioRecordings.sourceLT", "undefined");
          }}
        />
      </label>

      <div className="py-2">
        <ErrorMessage
          name="audioRecordings.nameRU"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="audioRecordings.nameRU"
          title="Audio pavadinimas rusiškai"
          type="text"
          value={formikValues.audioRecordings.nameRU}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("audioRecordings.nameRU", e.target.value)
          }
        />
      </div>

      <ErrorMessage
        name="audioRecordings.sourceRU"
        className="text-red-400"
        render={(msg) => <p className="text-red-500">{msg}</p>}
      />
      {inputValue.sourceRU && <p>{inputValue.sourceRU}</p>}
      <label
        htmlFor="inputFileSourceRU"
        className="bg-darkPurple cursor-pointer"
      >
        <div className="flex justify-center items-center p-4 gap-5">
          <p className="text-white">Įkelti rusiška audio įrašą</p>
          <FontAwesomeIcon
            className="text-white text-2xl"
            icon={faCirclePlay}
          />
        </div>
        <input
          className="hidden"
          id="inputFileSourceRU"
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue(
              "audioRecordings.sourceRU",
              sourceValidation(e.target.files![0])
            );
            inputValue.sourceRU = e.target.files![0].name;
            setInputValue(inputValue);
          }}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            e.currentTarget.value = "";
          }}
        />
      </label>

      <div className="py-2">
        <ErrorMessage
          name="descriptionEN"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionEN"
          title="Artefakto aprašymas angliškai"
          value={formikValues.descriptionEN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionEN", e.target.value)
          }
        />
      </div>

      <div className="py-2">
        <ErrorMessage
          name="descriptionLT"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionLT"
          title="Artefakto aprašymas"
          value={formikValues.descriptionLT}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionLT", e.target.value)
          }
        />
      </div>

      <div className="py-2">
        <ErrorMessage
          name="descriptionRU"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionRU"
          title="Artefakto aprašymas rusiškai"
          value={formikValues.descriptionRU}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionRU", e.target.value)
          }
        />
      </div>
      <ErrorMessage
        name="photo"
        className="text-red-400"
        render={(msg) => <p className="text-red-500">{msg}</p>}
      />
      {photoUrl && (
        <div className="py-4">
          <label>Dabartinė nuotrauką</label>
          {updatedImage !== null ? (
            <img
              className="object-cover h-48"
              src={updatedImage?.toString()}
              alt="routeImage"
            />
          ) : (
            <img className="object-cover h-48" src={photoUrl} />
          )}
        </div>
      )}
      {loading.photo ? (
        <Loader />
      ) : (
        inputValue.photo && (
          <div>
            <label>Nauja nuotrauka</label>
            <div className="flex flex-col bg-gray">
              <div className="p-4">
                {image !== null && (
                  <img
                    className="object-cover h-48"
                    src={image.toString()}
                    alt="routeImage"
                  />
                )}
              </div>
              <div className="flex items-center text-darkPurple p-4">
                <FontAwesomeIcon
                  className="text-darkPurple text-2xl pr-4"
                  icon={faImage}
                />
                <p>{inputValue.photo}</p>
              </div>
            </div>
          </div>
        )
      )}
      <label htmlFor="inputFile" className="bg-darkPurple cursor-pointer">
        <div className="flex justify-center items-center p-4 gap-5">
          <p className="text-white">Įkelti nuotrauką</p>
          {uploaded.photo ? (
            <FontAwesomeIcon className="text-white text-2xl" icon={faCheck} />
          ) : (
            <FontAwesomeIcon className="text-white text-2xl" icon={faImage} />
          )}
        </div>
        <input
          className="hidden"
          id="inputFile"
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue("photo", photoValidation(e.target.files![0]));
            inputValue.photo = e.target.files![0].name;
            setPhotoFile(e.target.files![0]);
            setInputValue(inputValue);
            loading.photo = true;
          }}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            initBooleanValues.photo = false;
            uploaded.photo = false;
            setLoading(initBooleanValues);
            inputValue.photo = "";
            e.currentTarget.value = "";
            setPhotoFile(null);
            setImage(null);
            setInputValue(inputValue);
            formikSetFieldValue("photo", "undefined");
          }}
        />
      </label>

      <button
        className="bg-gradient-to-r bg-softPurple hover:bg-darkPurple  my-5 text-white p-4  transition duration-700 ease-in-out"
        onClick={() => {
          if (geoPoint?.lat === 0 && geoPoint.lng === 0) {
            return setMapError("Pasirinkite artefakta žemėlapije!");
          } else if (
            geoPoint?.lat! > 0 &&
            geoPoint?.lng! > 0 &&
            Object.keys(formikErrors).length === 0
          ) {
            setUpdatedImage(image);
            setUpdatedAudio(audio);
            setInputValue(initInputValues);
            setUploaded(uploadedBoolean);
            return setMapError("");
          }
        }}
        type="submit"
      >
        Išsaugoti
      </button>
    </Form>
  );
};

export default ArtefactForm;
