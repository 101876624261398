import axios from 'axios';
import { Formik, FormikState } from 'formik';
import React, { useEffect, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import GameRouteModal from '../modals/Modal';
import Loader from '../Loader';
import AdminForm from '../forms/AdminForm';
import { useParams } from 'react-router-dom';
import { updateAdminSchema, updateAdminInputs } from '../../schema/updateAdminSchema';

const AdminUpdate: React.FC = () => {
  const uid = useParams().id;
  const [modal, setModal] = useState(false);
  const [error, setError] = useState('');
  const [admin, setAdmin] = useState<{ uid: string; email: string }>();

  const [loading, setLoading] = useState(false);

  const getAdmin = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/admin/${uid}`, { withCredentials: true });
      setAdmin(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const handleSubmit = async (values: { email?: string; password?: string }) => {
    if (values.email === '') {
      delete values.email;
    }

    if (values.password === '') {
      delete values.password;
    }

    try {
      await axios.patch(`/admin/${uid}`, values, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
        },
      });

      setError('');
      setModal(true);
    } catch (error: any) {
      setError(error.response.data.issues[0].message);
    }
  };

  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && <GameRouteModal modal={modal} setModal={setModal} text="Administratorius atnaujintas!" />}
      {error && <p className="text-red-600">{error}</p>}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: admin?.email || '',
          password: '',
        }}
        validationSchema={toFormikValidationSchema(updateAdminSchema)}
        onSubmit={(values: updateAdminInputs, { resetForm }) => {
          handleSubmit(values);
          values.password = '';
        }}
      >
        {(formik) => {
          return <AdminForm formikSetFieldValue={formik.setFieldValue} formikValues={formik.values} />;
        }}
      </Formik>
    </div>
  );
};

export default AdminUpdate;
