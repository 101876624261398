import axios from "axios";
import FormData from "form-data";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { IArtefact, IArtefactArr } from "../../interface/artefacts/artefact";
import { GameRoute } from "../../interface/gameRoutes/Route";
import {
  updateGameRouteInputs,
  updateGameRouteSchema,
} from "../../schema/updateGameRouteSchema";
import { checkDifLevel } from "../../utils/checkDifLevel";
import { checkRegion } from "../../utils/checkRegions";
import GameRouteForm from "../forms/GameRouteForm";
import GoogleMapUpdate from "../googleMap/routesMaps/GoogleMapUpdate";
import Search from "../inputs/Search";
import Loading from "../Loader";
import GameRouteModal from "../modals/Modal";

const UpdateGameRoutes: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [gameRoute, setGameRoute] = useState<GameRoute>();
  const id = useParams().id;
  const [polylines, setPolylines] = useState<{ lat: number; lng: number }[]>(
    []
  );

  const [artefacts, setArtefacts] = useState<IArtefactArr[]>([]);
  const [meters, setMeters] = useState<number>(0);
  const [selectedArtefacts, setSelectedArtefacts] = useState<IArtefact[]>([]);
  const [removeArtefact, setRemoveArtefact] = useState<IArtefact[]>([]);
  const [mapError, setMapError] = useState("");
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState<string>("");
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    setChangeCount(changeCount + 1);
    if (changeCount > 1) {
      return;
    }
  }, [selectedArtefacts, polylines]);

  const getGameRoute = async () => {
    try {
      const res = await axios.get(`/route/${id}`, {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      setGameRoute(res.data);
      setPhotoUrl(res.data.photo);

      const newPolylineArr: { lat: number; lng: number }[] = [];

      res.data.encodedPolyline.forEach((polyline: number[]) => {
        newPolylineArr.push({ lat: polyline[0], lng: polyline[1] });
      });

      setPolylines(newPolylineArr);
      setMeters(res.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllArtefacts = async () => {
    const newArrtefactArr: IArtefactArr[] = [];
    const newSelectedArrtefactArr: IArtefactArr[] = [];
    const res = await axios.get("/artefact", {
      withCredentials: true,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    res.data.forEach((artefact: IArtefactArr) => {
      if (gameRoute?.artefactsIds.includes(artefact.id)) {
        newSelectedArrtefactArr.push(artefact);
      } else {
        newArrtefactArr.push(artefact);
      }
    });

    setArtefacts(newArrtefactArr);

    newSelectedArrtefactArr.sort(
      (a, b) =>
        gameRoute!.artefactsIds.indexOf(a.id) -
        gameRoute!.artefactsIds.indexOf(b.id)
    );

    setSelectedArtefacts(newSelectedArrtefactArr);
  };

  useEffect(() => {
    getGameRoute();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (gameRoute !== undefined) {
      getAllArtefacts();
    }
  }, [gameRoute]);

  const handleSubmit = async (values: updateGameRouteInputs) => {
    const formData = new FormData();
    formData.append("photo", values.photo);

    const fileNameExist = values.photo!.name !== undefined;

    setModal(true);

    const selectedArtfeactIds: string[] = [];

    selectedArtefacts.forEach((artefact) => {
      selectedArtfeactIds.push(artefact.id);
    });

    values.length = meters;
    values.artefactsIds = selectedArtfeactIds;

    const res = await axios.patch(`/route/${id}`, values, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 200 && fileNameExist) {
      await axios.patch(`/route/upload/${id}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
    }

    // if (res.status === 200) {
    //   selectedArtefacts.forEach(async (artefact, index) => {
    //     await axios.patch(
    //       `/artefact/routeId/${artefact.docId}`,
    //       { routeId: id, id: index + 1 },
    //       {
    //         withCredentials: true,
    //         headers: {
    //           Accept: "application/json",
    //         },
    //       }
    //     );
    //   });

    // artefacts.forEach(async (artefact) => {
    //   await axios.patch(
    //     `/artefact/routeId/${artefact.docId}`,
    //     { routeId: "", id: null },
    //     {
    //       withCredentials: true,
    //       headers: {
    //         Accept: "application/json",
    //       },
    //     }
    //   );
    // });
    // }
    setArtefacts([]);
  };

  if (loading) {
    return (
      <div className="flex">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && (
        <GameRouteModal
          modal={modal}
          setModal={setModal}
          text="Maršrutas atnaujintas!"
        />
      )}
      <Search
        artefacts={artefacts}
        setArtefacts={setArtefacts}
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setRemoveArtefact={setRemoveArtefact}
      />

      <div className="bg-gradient-to-r from-darkPurple to-softPurple p-1 my-5">
        {!loading && (
          <GoogleMapUpdate
            setPolylines={setPolylines}
            polylines={polylines}
            selectedArtefacts={selectedArtefacts}
            setSelectedArtefacts={setSelectedArtefacts}
            setArtefacts={setArtefacts}
            artefacts={artefacts}
            setMeters={setMeters}
            meters={meters}
          />
        )}
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          author: gameRoute?.author || "",
          nameEN: gameRoute?.nameEN || "",
          // nameEN: "",
          nameLT: gameRoute?.nameLT || "",
          // nameRU: "",
          nameRU: gameRoute?.nameRU || "",
          // descriptionEN: "",
          descriptionEN: gameRoute?.descriptionEN || "",
          descriptionLT: gameRoute?.descriptionLT || "",
          // descriptionRU: "",
          descriptionRU: gameRoute?.descriptionRU || "",
          difficultyLevel: checkDifLevel(gameRoute?.difficultyLevel!),
          length: meters,
          location: {
            geopoint: {
              latitude: gameRoute?.location.geopoint.latitude!,
              longitude: gameRoute?.location.geopoint.longitude!,
            },
          },
          region: checkRegion(gameRoute?.region!),
          encodedPolyline: polylines,
          photo: {},
          stepCount: gameRoute?.stepCount! || 0,
          walkingTime: gameRoute?.walkingTime! || 0,
        }}
        validationSchema={toFormikValidationSchema(updateGameRouteSchema)}
        onSubmit={(values) => {
          handleSubmit(values);
          values.photo = {};
        }}
      >
        {(formik) => {
          return (
            <GameRouteForm
              dirty={formik.dirty || changeCount > 1}
              formikSetFieldValue={formik.setFieldValue}
              formikValues={formik.values}
              meters={meters}
              setMapError={setMapError}
              formikErrors={formik.errors}
              photoUrl={photoUrl}
              setPhotoUrl={setPhotoUrl}
              polylines={polylines}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateGameRoutes;
