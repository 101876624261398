import { faPencil } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FavIcon from "../../components/buttons/fAwesomeButton";
import ViewGameRoute from "../../components/gameRoute/ViewGameRoute";
import { GameRoute } from "../../interface/gameRoutes/Route";

const GameRoutesView = () => {
  const id = useParams().id;
  const [gameRoutes, setGameRoutes] = useState<GameRoute>();

  useEffect(() => {
    const getGameRoute = async () => {
      try {
        const res = await axios.get(`/route/${id}`, {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        setGameRoutes(res.data);
      } catch (error) {}
    };

    getGameRoute();
  }, [setGameRoutes]);

  return (
    <div className="flex flex-col items-cener gap-5 p-10 h-full">
      <div className="flex justify-between bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
        <p className="text-white">Maršrutas</p>
        <FavIcon
          to={`/routes/update/${id}`}
          icon={faPencil}
          color={"text-white"}
          size={"text-md"}
        />
      </div>

      <div className="flex justify-center h-full">
        <ViewGameRoute gameRoutes={gameRoutes} />
      </div>
    </div>
  );
};

export default GameRoutesView;
