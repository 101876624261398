import { any, record, z } from "zod";

export const addArtefactSchema = z.object({
  audioRecordings: z.object({
    nameEN: z.string({
      required_error: "Audio pavadinimas angliškai privalomas!",
    }),
    nameLT: z.string({
      required_error: "Audio pavadinimas privalomas!",
    }),
    nameRU: z.string({
      required_error: "Audio pavadinimas rusiškai privalomas!",
    }),
    sourceEN:
      typeof window === "undefined"
        ? z.string().optional()
        : record(any()).optional(),
    sourceLT:
      typeof window === "undefined"
        ? z.string().optional()
        : record(any()).optional(),
    sourceRU:
      typeof window === "undefined"
        ? z.string().optional()
        : record(any()).optional(),
    playAudio: z.boolean(),
  }),
  latitude: z
    .number()
    .or(z.string().regex(/\d+/).transform(Number))
    .refine((n) => n >= 0),
  longitude: z
    .number()
    .or(z.string().regex(/\d+/).transform(Number))
    .refine((n) => n >= 0),
  descriptionEN: z.string({
    required_error: "Aprašymas angliškai privalomas!",
  }),
  descriptionLT: z.string({
    required_error: "Aprašymas is privalomas!",
  }),
  descriptionRU: z.string({ required_error: "Aprašymas rusiškai privalomas!" }),
  nameEN: z.string({ required_error: "Pavadinimas angliškai privalomas!" }),
  nameLT: z.string({ required_error: "Pavadinimas privalomas!" }),
  nameRU: z.string({ required_error: "PAvadinimas rusiškai privalomas!" }),
  photo:
    typeof window === "undefined"
      ? z.string().optional()
      : record(any()).optional(),
});

export type addArtefactSchemaInputs = z.infer<typeof addArtefactSchema>;
