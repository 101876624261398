import axios from "axios";
import FormData from "form-data";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { IArtefact } from "../../interface/artefacts/artefact";
import {
  updateArtefactSchema,
  updateArtefactSchemaInputs,
} from "../../schema/updateArtefact.schema";
import ArtefactForm from "../forms/ArtefactForm";
import ArtefactGoogleMaps from "../googleMap/artefactMaps/ArtefactGoogleMap";
import Loader from "../Loader";
import GameRouteModal from "../modals/Modal";

const UpdateArtefact: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [artefacts, setArtefacts] = useState<IArtefact>();
  const [geoPoint, setGeopoint] = useState<{ lat: number; lng: number }>();
  const [mapError, setMapError] = useState("");
  const id = useParams().id;
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    setChangeCount(changeCount + 1);
    if (changeCount > 1) {
      return;
    }
  }, [geoPoint]);

  const getGameRoute = async () => {
    try {
      const res = await axios.get(`/artefact/${id}`, { withCredentials: true });
      setGeopoint({ lat: res.data.latitude, lng: res.data.longitude });
      setPhotoUrl(res.data.photo);
      setArtefacts(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGameRoute();
  }, [setArtefacts, setModal, setGeopoint]);

  const handleSubmit = async (values: updateArtefactSchemaInputs) => {
    const sourceData = new FormData();
    const photoData = new FormData();

    const sources = [
      { name: "sourceEN", value: values.audioRecordings.sourceEN },
      { name: "sourceRU", value: values.audioRecordings.sourceRU },
      { name: "sourceLT", value: values.audioRecordings.sourceLT },
    ];

    photoData.append("photo", values.photo);

    for (let i = 0; i < sources.length; i += 1) {
      let x = sources[i];
      sourceData.append(x.name, x.value);
    }

    const sourceExist =
      values.audioRecordings.sourceEN!.name !== undefined &&
      values.audioRecordings.sourceLT!.name !== undefined &&
      values.audioRecordings.sourceRU!.name !== undefined;

    const photoExist = values.photo!.name !== undefined;

    delete values.audioRecordings.sourceEN;
    delete values.audioRecordings.sourceLT;
    delete values.audioRecordings.sourceRU;
    delete values.photo;

    setModal(true);

    const res = await axios.patch(`/artefact/${id}`, values, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 200) {
      if (sourceExist) {
        await axios.patch(`/artefact/upload/audio/${id}`, sourceData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
      }
      if (photoExist) {
        await axios.patch(`/artefact/upload/photo/${id}`, photoData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && (
        <GameRouteModal
          modal={modal}
          setModal={setModal}
          text="Artefaktas atnaujintas!"
        />
      )}

      {mapError && <div className="text-red-500">{mapError}</div>}
      <div className="bg-gradient-to-r from-darkPurple to-softPurplep-1 my-5">
        <ArtefactGoogleMaps setGeoPoint={setGeopoint} geoPoint={geoPoint} />
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{
          audioRecordings: {
            nameEN: artefacts?.audioRecordings.nameEN || "",
            nameLT: artefacts?.audioRecordings.nameLT || "",
            nameRU: artefacts?.audioRecordings.nameRU || "",
            sourceEN: {},
            sourceLT: {},
            sourceRU: {},
            playAudio: artefacts?.audioRecordings.playAudio || false,
          },
          descriptionEN: artefacts?.descriptionEN || "",
          descriptionLT: artefacts?.descriptionLT || "",
          descriptionRU: artefacts?.descriptionRU || "",
          latitude: geoPoint?.lat || 0,
          longitude: geoPoint?.lng || 0,
          nameEN: artefacts?.nameEN || "",
          nameLT: artefacts?.nameLT || "",
          nameRU: artefacts?.nameRU || "",
          photo: {},
        }}
        validationSchema={toFormikValidationSchema(updateArtefactSchema)}
        onSubmit={(values: updateArtefactSchemaInputs) => {
          handleSubmit(values);

          artefacts!.nameEN = values.nameEN;
          artefacts!.nameLT = values.nameLT;
          artefacts!.nameRU = values.nameRU;
          artefacts!.audioRecordings.nameEN = values.audioRecordings.nameEN;
          artefacts!.audioRecordings.nameLT = values.audioRecordings.nameLT;
          artefacts!.audioRecordings.nameRU = values.audioRecordings.nameRU;
          artefacts!.descriptionEN = values.descriptionEN;
          artefacts!.descriptionLT = values.descriptionLT;
          artefacts!.descriptionRU = values.descriptionRU;
          values.photo = {};
        }}
      >
        {(formik) => {
          return (
            <ArtefactForm
              dirty={formik.dirty || changeCount > 1}
              formikErrors={formik.errors}
              formikSetFieldValue={formik.setFieldValue}
              formikValues={formik.values}
              setMapError={setMapError}
              geoPoint={geoPoint}
              photoUrl={photoUrl}
              audioUrl={artefacts?.audioRecordings.sourceLT}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateArtefact;
