import { useLoadScript } from "@react-google-maps/api";
import { useEffect } from "react";
import { IArtefact } from "../../../interface/artefacts/artefact";
import { calcDis } from "../../../utils/calcDistance";
import Loader from "../../Loader";
import Map from "./Map";

const GoogleMaps: React.FC<{
  setPolylines: (setPolylines: { lat: number; lng: number }[]) => void;
  meters: number;
  setMeters: (setMeters: number) => void;
  polylines: { lat: number; lng: number }[];
  selectedArtefacts: IArtefact[];
  artefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  setArtefacts: (setArtefacts: IArtefact[]) => void;
}> = ({
  setPolylines,
  polylines,
  setMeters,
  selectedArtefacts,
  setSelectedArtefacts,
  setArtefacts,
  artefacts,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
  });

  useEffect(() => {
    setMeters(calcDis(polylines));
  }, [polylines]);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <Map
        setPolylines={setPolylines}
        polylines={polylines}
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setArtefacts={setArtefacts}
        artefacts={artefacts}
      />
    </div>
  );
};

export default GoogleMaps;
