export const sourceValidation = (sourceFile: File) => {
  const FILE_SIZE = 5000000;
  const SUPPORTED_FORMATS = ["audio/mpeg", "audio/wav"];

  if (FILE_SIZE < sourceFile.size) {
    return "sourceFileIsTooBig";
  }

  if (!SUPPORTED_FORMATS.includes(sourceFile.type)) {
    return "badSourceFileType";
  }

  return sourceFile;
};
