import { faCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form } from "formik";
import React, { useEffect, useState } from "react";

import { usePrompt } from "../../hooks/usePromptBlocker";
import { IGameRouteForm } from "../../interface/gameRoutes/Route";
import { DifficultyLevel, Region } from "../../schema/addGameRouteSchema";
import { photoValidation } from "../../utils/imageValidation";
import FormTextarea from "../inputs/FormikTextarea";
import FormInput from "../inputs/FormInput";
import NumberInput from "../inputs/NumberInput";
import FormSelectInput from "../inputs/SelectInput";
import Loader from "../Loader";

const GameRouteForm: React.FC<IGameRouteForm> = ({
  dirty,
  formikValues,
  formikSetFieldValue,
  meters,
  setMapError,
  polylines,
  formikErrors,
  photoUrl,
  setPhotoUrl,
}) => {
  usePrompt(
    "Ar tikrai norite išeiti? Neišsaugoti duomenys bus prarasti.",
    dirty
  );
  const initInputValues = { photo: "" };
  const [inputValue, setInputValue] = useState(initInputValues);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [updatedImage, setUpdatedImage] = useState<string | ArrayBuffer | null>(
    null
  );

  const fileOnchange = () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target!.result);
    };
    if (file !== null) {
      reader.readAsDataURL(file!);
    }
  };

  useEffect(() => {
    fileOnchange();
    if (inputValue.photo !== "") {
      setLoading(false);
      setUploaded(true);
    }
  });

  return (
    <Form autoComplete="off" className="grid gap-3">
      <div className="py-2">
        <ErrorMessage
          name="author"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="author"
          title="Autorius"
          value={formikValues.author}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("author", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="nameEN"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameEN"
          title="Pavadinimas angliškai"
          value={formikValues.nameEN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameEN", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="nameLT"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameLT"
          title="Pavadinimas"
          value={formikValues.nameLT}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameLT", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="nameRU"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormInput}
          name="nameRU"
          title="Pavadinimas rusiškai"
          value={formikValues.nameRU}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("nameRU", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="descriptionEN"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionEN"
          title="Aprašymas angliškai"
          value={formikValues.descriptionEN}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionEN", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="descriptionLT"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionLT"
          title="Aprašymas"
          value={formikValues.descriptionLT}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionLT", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="descriptionRU"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormTextarea}
          name="descriptionRU"
          title="Aprašymas rusiškai"
          value={formikValues.descriptionRU}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("descriptionRU", e.target.value)
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="region"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={FormSelectInput}
          name="region"
          title="Regionas"
          value={formikValues.region}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("region", e.target.value)
          }
          obj={Region}
          selectedValue={formikValues.region}
        />
      </div>
      <div className="py-2">
        <p>Atstumas metrais</p>
        <div className="bg-gray p-4 w-full select-none">{meters}</div>
      </div>
      <div className="py-2">
        <ErrorMessage
          name="stepCount"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={NumberInput}
          name="stepCount"
          title="Žingnių skaičius"
          value={formikValues.stepCount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formikSetFieldValue("stepCount", parseInt(e.target.value))
          }
        />
      </div>
      <div className="py-2">
        <ErrorMessage
          name="walkingTime"
          className="text-red-400"
          render={(msg) => <p className="text-red-500">{msg}</p>}
        />
        <Field
          as={NumberInput}
          name="walkingTime"
          title="Vaikščiojimo laikas minutėmis"
          value={formikValues.walkingTime}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formikSetFieldValue("walkingTime", parseInt(e.target.value));
          }}
        />
      </div>
      <Field
        as={FormSelectInput}
        name="difficultyLevel"
        title="Sudėtingumo lygis"
        value={formikValues.difficultyLevel}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          formikSetFieldValue("difficultyLevel", e.target.value)
        }
        obj={DifficultyLevel}
        selectedValue={formikValues.difficultyLevel}
      />
      <ErrorMessage
        name="photo"
        className="text-red-400"
        render={(msg) => <p className="text-red-500">{msg}</p>}
      />
      {photoUrl && (
        <div className="py-4">
          <label>Dabartinė nuotrauką</label>
          <div className="bg-gray p-5">
            {updatedImage !== null ? (
              <img
                className="object-cover h-48"
                src={updatedImage?.toString()}
                alt="routeImage"
              />
            ) : (
              <img className="object-cover h-48" src={photoUrl} />
            )}
          </div>
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (
        inputValue.photo && (
          <div>
            <label>Nauja nuotrauka</label>
            <div className="flex flex-col bg-gray">
              <div className="p-4">
                {image !== null && (
                  <img
                    className="object-cover h-48"
                    src={image.toString()}
                    alt="routeImage"
                  />
                )}
              </div>
              <div className="flex items-center text-darkPurple p-4">
                <FontAwesomeIcon
                  className="text-darkPurple text-2xl pr-4"
                  icon={faImage}
                />
                <p>{inputValue.photo}</p>
              </div>
            </div>
          </div>
        )
      )}
      <label htmlFor="inputFile" className="bg-darkPurple cursor-pointer mt-5">
        <div className="flex justify-center items-center p-4 gap-5">
          <p className="text-white">Įkelti nuotrauką</p>
          {uploaded ? (
            <FontAwesomeIcon className="text-white text-2xl" icon={faCheck} />
          ) : (
            <FontAwesomeIcon className="text-white text-2xl" icon={faImage} />
          )}
        </div>
        <input
          className="hidden"
          id="inputFile"
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);
            formikSetFieldValue("photo", photoValidation(e.target.files![0]));

            inputValue.photo = e.target.files![0].name;
            setFile(e.target.files![0]);
            setInputValue(inputValue);
          }}
          onClick={(e: React.FormEvent<HTMLInputElement>) => {
            e.currentTarget.value = "";
            setInputValue(initInputValues);
            setUploaded(false);
            formikSetFieldValue("photo", "undefined");
          }}
        />
      </label>

      <button
        className="bg-gradient-to-r bg-softPurple hover:bg-darkPurple  my-5 text-white p-4  transition duration-700 ease-in-out"
        onClick={() => {
          if (polylines?.length === 0) {
            return setMapError("Pasirinkite maršrutą žemėlapije!");
          } else if (
            polylines?.length! > 0 &&
            Object.keys(formikErrors).length === 0
          ) {
            setUpdatedImage(image);
            setInputValue(initInputValues);
            setUploaded(false);
            return setMapError("");
          }
        }}
        type="submit"
      >
        Išsaugoti
      </button>
    </Form>
  );
};

export default GameRouteForm;
