import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import FormInput from '../components/inputs/FormInput';
import Modal from '../components/modals/Modal';
import { resetPasswordSchema, resetPasswordSchemaInputs } from '../schema/resetPasswordSchema';

const ResetPassword: React.FC<{ setSendMessage: (setSendMessage: string) => void }> = ({ setSendMessage }) => {
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [color, setColor] = useState('bg-emerald-500');
  const navigate = useNavigate();

  const handleSubmit = async (values: { email: string }) => {
    try {
      const res = await axios.post('/forgotPassword', values, { withCredentials: true });
      setSendMessage(res.data.message);
      setModal(true);
      navigate('/');
    } catch (error: any) {
      setColor('bg-red-500');
      setMessage(error.response.data.message);
      setModal(true);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={toFormikValidationSchema(resetPasswordSchema)}
        onSubmit={(values: resetPasswordSchemaInputs) => {
          handleSubmit(values);
        }}
      >
        {(formik) => {
          return (
            <div className="flex flex-col items-cener gap-5 p-10 h-full w-full">
              {modal && <Modal modal={modal} setModal={setModal} text={message} time={5000} color={color} />}
              <div className="bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
                <p className="text-white">Slaptažodžio atkūrimas</p>
              </div>
              <div className="w-full h-full">
                <Form className="flex flex-col gap-5">
                  <div className="w-full">
                    <ErrorMessage name="email" className="text-red-400" render={(msg) => <p className="text-red-500">{msg}</p>} />
                    <Field
                      as={FormInput}
                      name="email"
                      title="El. paštas"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('email', e.target.value)}
                    />
                  </div>

                  <button className="bg-darkPurple p-4 text-white rounded hover:bg-softPurple transition">Atkurti</button>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetPassword;
