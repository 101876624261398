import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoutes: React.FC<{
  isLogged: boolean;
  children?: any;
  isSuperAdmin?: boolean;
}> = ({ isLogged, children, isSuperAdmin = false }) => {
  const location = useLocation().pathname;

  if (isLogged === false && location === '/') {
    return <Navigate to="/home" />;
  } else if (isSuperAdmin === false && location.includes('/admin')) {
    return <Navigate to="/home" />;
  } else if (isLogged) {
    <Navigate to="/home" />;
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoutes;
