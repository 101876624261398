import axios from "axios";
import FormData from "form-data";
import { Formik, FormikState } from "formik";
import React, { useEffect, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import {
  addArtefactSchema,
  addArtefactSchemaInputs,
} from "../../schema/addArtefact.Schema";
import ArtefactForm from "../forms/ArtefactForm";
import ArtefactGoogleMaps from "../googleMap/artefactMaps/ArtefactGoogleMap";
import Loader from "../Loader";
import GameRouteModal from "../modals/Modal";

const ArtefactsCreate: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [geoPoint, setGeopoint] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [mapError, setMapError] = useState("");
  const [loading, setLoading] = useState(true);
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    setChangeCount(changeCount + 1);
    if (changeCount > 1) {
      return;
    }
  }, [geoPoint]);

  useEffect(() => {
    const removingMapErorr = () => {
      if (geoPoint.lat > 0 && geoPoint.lng > 0) {
        setMapError("");
      }
    };

    removingMapErorr();
    setLoading(false);
  }, [geoPoint]);

  const handleSubmit = async (
    values: addArtefactSchemaInputs,
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              photo?: string | Record<string, any> | undefined;
              audioRecordings: {
                sourceEN?: string | Record<string, any> | undefined;
                sourceLT?: string | Record<string, any> | undefined;
                sourceRU?: string | Record<string, any> | undefined;
                nameEN: string;
                nameLT: string;
                nameRU: string;
                playAudio: boolean;
              };
              nameEN: string;
              nameLT: string;
              nameRU: string;
              latitude: number;
              longitude: number;
              descriptionEN: string;
              descriptionLT: string;
              descriptionRU: string;
            }>
          >
        | undefined
    ) => void
  ) => {
    if (geoPoint.lat === 0 && geoPoint.lng === 0) {
      return setMapError("Pasirinkite artefakta žemėlapije!");
    }

    values.latitude = geoPoint.lat;
    values.longitude = geoPoint.lng;

    const sourceData = new FormData();
    const photoData = new FormData();
    const sources = [
      { name: "sourceEN", value: values.audioRecordings.sourceEN },
      { name: "sourceRU", value: values.audioRecordings.sourceRU },
      { name: "sourceLT", value: values.audioRecordings.sourceLT },
    ];

    photoData.append("photo", values.photo);

    for (let i = 0; i < sources.length; i += 1) {
      let x = sources[i];
      sourceData.append(x.name, x.value);
    }

    delete values.audioRecordings.sourceEN;
    delete values.audioRecordings.sourceLT;
    delete values.audioRecordings.sourceRU;
    delete values.photo;

    setModal(true);

    const res = await axios.post("/artefact", values, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });

    resetForm({});

    if (res.status === 201) {
      await axios.post(`/artefact/upload/audio/${res.data.id}`, sourceData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      await axios.post(`/artefact/upload/photo/${res.data.id}`, photoData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
    }
    setGeopoint({ lat: 0, lng: 0 });
  };

  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      {modal && (
        <GameRouteModal
          modal={modal}
          setModal={setModal}
          text="Artefaktas sukurtas!"
        />
      )}

      <div>
        {mapError && <div className="text-red-500">{mapError}</div>}
        <div>
          <p className="text-darkPurple text-xl font-bold">
            Pasirinkite artefaktą
          </p>
        </div>
        <div className="bg-gradient-to-r from-darkPurple to-softPurple p-1 my-5">
          <ArtefactGoogleMaps setGeoPoint={setGeopoint} geoPoint={geoPoint} />
        </div>
      </div>

      <Formik
        initialValues={{
          audioRecordings: {
            nameEN: "",
            nameLT: "",
            nameRU: "",
            sourceEN: "",
            sourceLT: "undefined",
            sourceRU: "",
            playAudio: true,
          },
          descriptionEN: "",
          descriptionLT: "",
          descriptionRU: "",
          latitude: 0,
          longitude: 0,
          photo: "undefined",
          nameEN: "",
          nameLT: "",
          nameRU: "",
        }}
        validationSchema={toFormikValidationSchema(addArtefactSchema)}
        onSubmit={(values: addArtefactSchemaInputs, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {(formik) => {
          return (
            <ArtefactForm
              dirty={formik.dirty || changeCount > 1}
              formikErrors={formik.errors}
              formikSetFieldValue={formik.setFieldValue}
              formikValues={formik.values}
              setMapError={setMapError}
              geoPoint={geoPoint}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default ArtefactsCreate;
