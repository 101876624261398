import { faPencil } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminView from '../../components/admins/AdminView';
import FavIcon from '../../components/buttons/fAwesomeButton';

const AdminViewPage = () => {
  const id = useParams().id;
  const [admin, setAdmin] = useState<{ uid: string; email: string }>();
  const [laoding, setLoading] = useState(true);

  useEffect(() => {
    const getAdmin = async () => {
      try {
        const res = await axios.get(`/admin/${id}`, {
          withCredentials: true,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        setAdmin(res.data);
        setLoading(false);
      } catch (error) {}
    };

    getAdmin();
  }, [setAdmin]);

  return (
    <div className="flex flex-col items-cener gap-5 p-10 h-full">
      <div className="flex justify-between bg-gradient-to-r from-darkPurple to-softPurple w-full p-5 h-auto">
        <p className="text-white">Administratorius</p>
        <FavIcon to={`/admin/update/${id}`} icon={faPencil} color={'text-white'} size={'text-md'} />
      </div>

      <div className="flex justify-center h-full">
        <AdminView loading={laoding} admin={admin} />
      </div>
    </div>
  );
};

export default AdminViewPage;
