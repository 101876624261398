const DeleteModal: React.FC<{
  item: string;
  setDeleteElement: (setDeleteElement: boolean) => void;
  setDeleteModal: (setDeleteModal: boolean) => void;
}> = ({ item, setDeleteElement, setDeleteModal }) => {
  return (
    <div className="absolute m-auto left-0 right-0 h-full w-full bottom-0 top-0 bg-black/60">
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col justify-center items-center bg-white rounded-[12px] p-5">
          <div className="p-5">
            <p className="text-xl select-none">Ar tikrai norite ištrinti šį {item}?</p>
          </div>
          <div className="flex p-5 gap-2">
            <button
              className="border-2 border-red-600 py-2 px-10 rounded-[12px] text-red-600 hover:bg-red-600 hover:text-white transition"
              onClick={() => {
                setDeleteElement(true);
                setDeleteModal(false);
              }}
            >
              Ištrinti
            </button>
            <button
              className="border-2 border-softPurple py-2 px-10 rounded-[12px] text-softPurple hover:bg-softPurple hover:text-white transition"
              onClick={() => {
                setDeleteElement(false);
                setDeleteModal(false);
              }}
            >
              Atšaukti
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
