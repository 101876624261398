import React from 'react';
import { Link } from 'react-router-dom';

const NavigationButton: React.FC<{
  title: string;
  to: string;
}> = ({ title, to }) => {
  return (
    <Link className="text-white opacity-70 whitespace-nowrap group-hover:opacity-100" to={to}>
      {title}
    </Link>
  );
};

export default NavigationButton;
