import React from 'react';

const FormTextarea: React.FC<{
  title: string;
  value?: string;
  name: string;
  onChange: () => void;
}> = ({ title, value, onChange, name }) => {
  return (
    <div>
      <div className="grid grid-cols-1 grid-rows-1 pb-0">
        <label>
          {title}
          <span className="text-red-400">*</span>
        </label>
      </div>
      <div>
        <textarea className="bg-gray p-4 w-full resize-none" name={name} value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default FormTextarea;
