import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const FavIcon: React.FC<{
  to: string;
  icon: IconDefinition;
  color: string;
  size: string;
}> = ({ to, icon, color, size }) => {
  return (
    <Link className="flex items-center" to={to}>
      <FontAwesomeIcon className={`${size} ${color}`} icon={icon} />
    </Link>
  );
};

export default FavIcon;
