import { any, record, z } from "zod";

export const updateArtefactSchema = z.object({
  audioRecordings: z.object({
    nameEN: z.string({
      required_error: "Audio pavadinimas angliškai privalomas!",
    }),
    nameLT: z.string({
      required_error: "Audio pavadinimas privalomas!",
    }),
    nameRU: z.string({
      required_error: "Audio pavadinimas rusiškai is privalomas!",
    }),
    sourceEN: record(any()).optional(),
    sourceLT: record(any()).optional(),
    playAudio: z.boolean(),
    sourceRU: record(any()).optional(),
  }),
  descriptionEN: z.string({
    required_error: "Aprašymas angliškai privalomas!",
  }),
  descriptionLT: z.string({
    required_error: "Aprašymas privalomas!",
  }),
  descriptionRU: z.string({ required_error: "Aprašymas rusiškai privalomas!" }),
  latitude: z.number({ required_error: "latitude is privalomas!" }),
  longitude: z.number({ required_error: "longitude is privalomas!" }),

  nameEN: z.string({ required_error: "Pavadinimas angliškai is privalomas!" }),
  nameLT: z.string({
    required_error: "Pavadinimas privalomas!",
  }),
  nameRU: z.string({ required_error: "Pavadinimas rusiškai privalomas!" }),
  photo: record(any()).optional(),
});

export type updateArtefactSchemaInputs = z.infer<typeof updateArtefactSchema>;
