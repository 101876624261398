import { Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { IArtefact } from '../../../interface/artefacts/artefact';
import { calcDis } from '../../../utils/calcDistance';

interface IArtefactMarkers {
  selectedArtefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  setArtefacts: (setArtefacts: IArtefact[]) => void;
  artefacts: IArtefact[];
}

const ArtefactMarkers: React.FC<IArtefactMarkers> = ({ selectedArtefacts, setSelectedArtefacts, setArtefacts, artefacts }) => {
  const [artefactsArr, setArtefactsArr] = useState<{ lat: number; lng: number }[]>([]);

  useEffect(() => {
    const selectArtefacts = () => {
      const arr: { lat: number; lng: number }[] = [];
      selectedArtefacts.forEach((artefact) => {
        arr.push({ lat: artefact.latitude, lng: artefact.longitude });
      });

      setArtefactsArr(arr);
    };

    selectArtefacts();
  }, [artefacts, selectedArtefacts]);

  const onClickRemove = (e: google.maps.MapMouseEvent) => {
    setArtefactsArr(artefactsArr.filter((artefact) => artefact.lat !== e.latLng!.lat() && artefact.lng !== e.latLng!.lng()));

    setSelectedArtefacts(
      selectedArtefacts.filter((artefact) => artefact.latitude !== e.latLng!.lat() && artefact.longitude !== e.latLng!.lng())
    );

    setArtefacts([
      ...artefacts,
      ...selectedArtefacts.filter((artefact) => artefact.latitude === e.latLng!.lat() && artefact.longitude === e.latLng!.lng()),
    ]);
  };

  return (
    <>
      {artefactsArr.map((marker: google.maps.LatLng | google.maps.LatLngLiteral, index) => {
        return <Marker key={index} position={marker} onDblClick={onClickRemove} label={`${index + 1}`} />;
      })}
    </>
  );
};

export default ArtefactMarkers;
