import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loader";
import AdminList from "../../components/admins/AdminList";

const Admins = () => {
  const [admins, setAdmins] = useState<{ uid: string; email: string }[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getAllAdmins = async () => {
      try {
        const res = await axios.get("/admins", {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        setAdmins(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getAllAdmins();
  }, [setAdmins]);

  if (loading) {
    return (
      <div className="flex justify-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-10 min-h-screen">
      <div className="flex justify-between items-center w-full h-auto">
        <p className="p-4 text-darkPurple text-xl">Administratorių sąrašas</p>
        <div className="p-1">
          <Link to={"/admin/create"}>
            <div className="text-white p-4 bg-softPurple hover:bg-darkPurple transition rounded-[12px]">
              Kurti administratorių
            </div>
          </Link>
        </div>
      </div>
      {admins.length !== 0 && (
        <AdminList admins={admins} setAdmins={setAdmins} />
      )}

      {admins.length === 0 && (
        <div className="w-full p-10">Administratorių nėra</div>
      )}
    </div>
  );
};

export default Admins;
