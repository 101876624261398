import { Routes, Route, useNavigate } from 'react-router-dom';
import Nav from './components/navbar/Nav';
import AdminDashbard from './pages/AdminDashboard';
import GameRoutes from './pages/gameRoutes/GameRoutes';
import GameRoutesView from './pages/gameRoutes/GameRoutesView';
import GameRoutesUpdate from './pages/gameRoutes/GameRouteUpdate';
import GameRouteCreate from './pages/gameRoutes/GameRoutesCreate';
import NotFound from './pages/NotFound';
import Artefacts from './pages/artefacts/Artefacts';
import ArtefactCreatePage from './pages/artefacts/ArtefactCreatePage';
import ArtefactUpdatePage from './pages/artefacts/ArtefactUpdatePage';
import ArtefactViewPage from './pages/artefacts/ArtefactViewPage';
import LoginPage from './pages/Login';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ProtectedRoutes from './ProtectedRoutes';
import Loader from './components/Loader';
import Admins from './pages/admin/Admins';
import AdminViewPage from './pages/admin/AdminView';
import AdminCreate from './pages/admin/AdminCreate';
import AdminUpdatePage from './pages/admin/AdminUpdate';
import ProfilePage from './pages/admin/ProfilePage';
import ResetPassword from './pages/ResetPassword';

const App = () => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [sendMessage, setSendMessage] = useState('');
  const navigate = useNavigate();

  const verify = async () => {
    try {
      const res = await axios.get('/verify', { withCredentials: true });

      setIsLogged(true);
      setLoading(false);
      setIsSuperAdmin(res.data.role);
    } catch (error) {
      setIsLogged(false);
      setLoading(false);
      setIsSuperAdmin(false);
      navigate('/');
    }
  };

  if (isLogged) {
    verify();
  }

  useEffect(() => {
    verify();
  }, [isLogged]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-64 md:min-h-screen">
        <Nav isLogged={isLogged} setIsLogged={setIsLogged} isSuperAdmin={isSuperAdmin} />
      </div>
      <div className="w-full">
        <Routes>
          <Route path="/home" element={<AdminDashbard />} />
          <Route path="/forgotPassword" element={<ResetPassword setSendMessage={setSendMessage} />} />
          <Route
            path="/"
            element={
              <ProtectedRoutes isLogged={!isLogged}>
                <LoginPage
                  setIsLogged={setIsLogged}
                  isLogged={isLogged}
                  setIsSuperAdmin={setIsSuperAdmin}
                  sendMessage={sendMessage}
                />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/routes"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <GameRoutes />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/routes/create"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <GameRouteCreate />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/routes/view/:id"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <GameRoutesView />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/routes/update/:id"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <GameRoutesUpdate />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/artefacts"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <Artefacts />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/artefacts/view/:id"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <ArtefactViewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/artefacts/create"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <ArtefactCreatePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/artefacts/update/:id"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <ArtefactUpdatePage />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/admins"
            element={
              <ProtectedRoutes isLogged={isLogged} isSuperAdmin={isSuperAdmin}>
                <Admins />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/admin/create"
            element={
              <ProtectedRoutes isLogged={isLogged} isSuperAdmin={isSuperAdmin}>
                <AdminCreate />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/admin/update/:id"
            element={
              <ProtectedRoutes isLogged={isLogged} isSuperAdmin={isSuperAdmin}>
                <AdminUpdatePage />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/admin/view/:id"
            element={
              <ProtectedRoutes isLogged={isLogged} isSuperAdmin={isSuperAdmin}>
                <AdminViewPage />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoutes isLogged={isLogged}>
                <ProfilePage />
              </ProtectedRoutes>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
