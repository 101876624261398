import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import FormInput from '../components/inputs/FormInput';
import Modal from '../components/modals/Modal';
import { loginSchema, loginSchemaInputs } from '../schema/login.schema';

const LoginPage: React.FC<{
  setIsLogged: (setIsLogged: boolean) => void;
  sendMessage: string;
  isLogged: boolean;
  setIsSuperAdmin: (setIsSuperAdmin: boolean) => void;
}> = ({ setIsLogged, isLogged, setIsSuperAdmin, sendMessage }) => {
  const [error, setError] = useState('');
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      const res = await axios.post('/login', values, { withCredentials: true });

      setIsSuperAdmin(res.data.role);

      setIsLogged(true);
      navigate('/home');
    } catch (error) {
      setError('Neteisingas El. paštas arba slaptažodis!');
    }
  };

  useEffect(() => {
    if (sendMessage !== '') {
      setModal(true);
    }
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={toFormikValidationSchema(loginSchema)}
        onSubmit={(values: loginSchemaInputs) => {
          handleSubmit(values);
        }}
      >
        {(formik) => {
          return (
            <div className="flex justify-center items-center h-screen">
              {modal && <Modal modal={true} setModal={setModal} text={sendMessage} time={5000} />}
              <div className="bg-softPurple p-1 rounded-[12px]">
                <div className="flex flex-col bg-white p-9 rounded-[12px] w-92">
                  <div className="flex justify-center items-center mb-9">
                    <p className="text-2xl text-darkPurple">Prisijungti</p>
                  </div>
                  {error && <p className="text-red-500">{error}</p>}

                  <Form className="flex flex-col gap-5">
                    <div>
                      <ErrorMessage
                        name="email"
                        className="text-red-400"
                        render={(msg) => <p className="text-red-500">{msg}</p>}
                      />
                      <Field
                        as={FormInput}
                        name="email"
                        title="El. paštas"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('email', e.target.value)}
                      />
                    </div>
                    <div>
                      <ErrorMessage
                        name="password"
                        className="text-red-400"
                        render={(msg) => <p className="text-red-500">{msg}</p>}
                      />
                      <Field
                        as={FormInput}
                        name="password"
                        InputType="password"
                        title="Slaptažodis"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('password', e.target.value)}
                      />
                    </div>
                    <div className="flex justify-end">
                      <p
                        className="text-softPurple cursor-pointer hover:underline hover:decoration-softPurple"
                        onClick={() => navigate('/forgotPassword')}
                      >
                        Pamiršote slaptažodi?
                      </p>
                    </div>
                    <button className="bg-darkPurple p-4 text-white rounded hover:bg-softPurple transition">Prisijungti</button>
                  </Form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginPage;
