export const photoValidation = (photoFile: File) => {
  const FILE_SIZE = 5000000;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  if (FILE_SIZE < photoFile.size) {
    return "imageFileIsTooBig";
  }

  if (!SUPPORTED_FORMATS.includes(photoFile.type)) {
    return "badImageFileType";
  }

  return photoFile;
};
