import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GameRoutesLists from "../../components/gameRoute/ListGameRoutes";
import { GameRoute } from "../../interface/gameRoutes/Route";
import Loading from "../../components/Loader";

const GameRoutes = () => {
  const [gameRoutes, setGameRoutes] = useState<GameRoute[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchGameRoutes = async () => {
      try {
        const res = await axios.get("/route", {
          withCredentials: true,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        setGameRoutes(res.data);
        setLoading(false);
      } catch (error) {}
    };

    fetchGameRoutes();
  }, [setGameRoutes]);

  if (loading) {
    return (
      <div className="flex justify-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-10">
      <div className="flex justify-between items-center w-full h-auto">
        <p className="p-4 text-xl text-darkPurple">Maršrutų sąrašas</p>
        <div className="p-1">
          <Link to={"/routes/create"}>
            <div className="text-white bg-softPurple hover:bg-darkPurple transition p-4 rounded-[12px]">
              Kurti maršrutą
            </div>
          </Link>
        </div>
      </div>
      <GameRoutesLists gameRoutes={gameRoutes} setGameRoutes={setGameRoutes} />
    </div>
  );
};

export default GameRoutes;
