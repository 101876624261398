import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';

const ArtefactMapView: React.FC<{
  geoPoint?: { lat: number; lng: number };
}> = ({ geoPoint }) => {
  const [position] = useState({
    lat: geoPoint?.lat!,
    lng: geoPoint?.lng!,
  });

  return (
    <GoogleMap zoom={14} center={position} mapContainerClassName="h-[32rem]">
      {geoPoint && <Marker position={{ lat: geoPoint?.lat, lng: geoPoint?.lng }} />}
    </GoogleMap>
  );
};

export default ArtefactMapView;
