export const calcDis = (polylinesArr: { lat: number; lng: number }[]) => {
  let distance: number = 0;
  for (let index = 0; index < polylinesArr.length - 1; index++) {
    const element0 = polylinesArr[index];
    const element1 = polylinesArr[index + 1];
    distance = distance + distanceC(element0, element1)!;
  }

  return distance;
};

const distanceC = (
  element0: { lat: number; lng: number },
  element1: { lat: number; lng: number }
) => {
  if (element0 && element1) {
    const moscow = new google.maps.LatLng(element0.lat, element0.lng);
    const leningrad = new google.maps.LatLng(element1.lat, element1.lng);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      moscow,
      leningrad
    );
    return Math.ceil(distance / 1);
  }
};
