import { GoogleMap, Polyline } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { IArtefact } from '../../../interface/artefacts/artefact';
import ArtefactMarkers from '../markers/ArtefackMarkers';
import PolylineMarkers from '../markers/PolylineMarkers';

const Map: React.FC<{
  setPolylines: (setPolylines: { lat: number; lng: number }[]) => void;
  polylines: { lat: number; lng: number }[];
  selectedArtefacts: IArtefact[];
  setSelectedArtefacts: (setSelectedArtefacts: IArtefact[]) => void;
  artefacts: IArtefact[];
  setArtefacts: (setArtefacts: IArtefact[]) => void;
}> = ({ setPolylines, polylines, selectedArtefacts, setSelectedArtefacts, setArtefacts, artefacts }) => {
  const [position] = useState({
    lat: 55.321918,
    lng: 23.958587,
  });

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const onClickAddMarker = (e: google.maps.MapMouseEvent) => {
    setPolylines([...polylines, { lat: e.latLng!.lat(), lng: e.latLng!.lng() }]);
  };

  return (
    <GoogleMap zoom={7} center={position} mapContainerClassName="h-[32rem]" onClick={onClickAddMarker}>
      <ArtefactMarkers
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setArtefacts={setArtefacts}
        artefacts={artefacts}
      />

      <PolylineMarkers
        setPolylines={setPolylines}
        polylines={polylines}
        selectedArtefacts={selectedArtefacts}
        setSelectedArtefacts={setSelectedArtefacts}
        setArtefacts={setArtefacts}
        artefacts={artefacts}
      />
      <Polyline path={polylines} options={options} />
    </GoogleMap>
  );
};

export default Map;
